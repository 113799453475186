import socialLikes from 'social-likes-next';
import copyToClipboard from 'src/javascript/components/copy_link';

$(document).on('turbolinks:load', function() {
  if ($('.js-entity_share').length) {
    $(document).on('click.entityShare', '.js-entity_share', function() {
      const entity = $(this).closest($('.entity'));

      let mql = window.matchMedia('(min-width: 768.98px)');

      if (mql.matches) {
        console.log('desc');
        const modalId = $(this).data('target');

        let entityCover = entity.hasClass('entity__single')
          ? entity.find('.entity-preview')[0].outerHTML
          : entity.find('.entity-preview')[0].outerHTML +
            entity.find('.top_lines')[0].outerHTML +
            entity.find('.top_corner')[0].outerHTML;

        let entityParams = {
          link: entity
            .find('a')
            .first()
            .attr('href'),
          title: entity.find('.name h3').text(),
          description: entity.find('.description').text(),
          cover: entityCover,
          stats: entity.find('.item-stats')[0].outerHTML
        };

        const linkMailTo = `mailto:?subject${entityParams.title}&body=Check out this ${entityParams.link}.`;

        let modalContent = $(modalId);

        modalContent
          .find('.share-preview')
          .html(entityParams.cover)
          .end()
          .find('.share-content h3')
          .text(entityParams.title)
          .end()
          .find('.share-stats')
          .html(entityParams.stats)
          .end()
          .find('.share-content p')
          .text(entityParams.description)
          .end()
          .find('.share-link__email')
          .attr('href', linkMailTo)
          .end()
          .find('.js-copy_link')
          .attr('data-href', entityParams.link)
          .end();

        let socialItem = document.querySelector('.js-social-likes');

        socialLikes(socialItem, {
          url: entityParams.link,
          title: entityParams.title
        });

        $(modalId).modal('show');

        $(modalId).on('click', '.js-copy_link', function() {
          event.preventDefault();
          copyToClipboard(entityParams.link, modalId);
        });
      } else {
        if (navigator.share) {
          navigator
            .share({
              title: entity.find('.name h3').text(),
              url: entity
                .find('a')
                .first()
                .attr('href')
            })
            .then(() => {
              console.log('Thanks for sharing!');
            })
            .catch(console.error);
        }
      }
    });
  }
});

$(document).on('turbolinks:before-render', function() {
  $(document).off('click.entityShare');
});

// if (matchMedia) {
//   const mq = window.matchMedia('(min-width: 767.98px)');

//   mq.addListener(WidthChange);
//   WidthChange(mq);
// }

// // media query change
// function WidthChange(mq) {
//   if (mq.matches) {
//     console.log('true', mq.matches);
//   } else {
//     console.log('false');
//   }
// }
