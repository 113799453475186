function FilterSubmit(){
  favorite = [];
  $.each($('input.celebrities-filter_btn:checked'), function() {
    favorite.push($(this).val());
    $('#filterData').val(favorite.join());
  });
  $('form.celebrities-filter_open').submit();
}

$(document).on('turbolinks:load', function() {
  let favorite = [];

  if ($('form').is('.celebrities-filter_open')) {
    const elemY = document.querySelector('.celebrities-filter_open').offsetTop;

    document.querySelector('.celebrities-filter').style.top = `${elemY}px`;
    document.querySelector('.celebrities-filter_blur').style.top = `${elemY}px`;
  }

  $('#celebritiesFilter').on('show.bs.collapse', function() {
    $('.celebrities-filter_blur').addClass('blured');
  });
  $('#celebritiesFilter').on('hide.bs.collapse', function() {
    $('.celebrities-filter_blur').removeClass('blured');
    FilterSubmit();
  });

  $.each($('input.celebrities-filter_btn:checked'), function() {
    favorite.push($(this).val());
  });

  $('button.btn-submit').click(function() {
     FilterSubmit();
  });

  $('button.btn-clear').click(function() {
    $.each($('input.celebrities-filter_btn:checked'), function() {
      $(this)[0].checked = false;
    });
  });

});
