import Swiper from 'swiper';
import axios from 'axios';

let myCategories = null;
let mySwipers = [];
let bannerSwiper = null;
let swiperMiddleOptions = {
  slidesPerView: 6,
  spaceBetween: 5,
  breakpoints: {
    320: {
      slidesPerView: 2
    },
    410: {
      slidesPerView: 3
    },
    575: {
      spaceBetween: 24,
      slidesPerView: 3
    },
    767: {
      slidesPerView: 3
    },
    991: {
      slidesPerView: 4
    },
    // -----
    1199: {
      slidesPerView: 3
    },
    1379: {
      slidesPerView: 4
    },
    1639: {
      slidesPerView: 5
    }
  }
};
let swiperWideOptions = {
  slidesPerView: 7,
  spaceBetween: 14,
  breakpoints: {
    320: {
      slidesPerView: 2
    },
    410: {
      slidesPerView: 3,
      spaceBetween: 5
    },
    575: {
      spaceBetween: 24,
      slidesPerView: 3
    },
    767: {
      slidesPerView: 3,
      spaceBetween: 5
    },
    991: {
      slidesPerView: 4
    },
    // -----
    1199: {
      slidesPerView: 5
    },
    1379: {
      slidesPerView: 6
    },
    1639: {
      slidesPerView: 6
    }
  }
};

let swiperHorizontalMiddleOptions = {
  slidesPerView: 4,
  spaceBetween: 14,
  breakpoints: {
    320: {
      slidesPerView: 1
    },
    410: {
      slidesPerView: 2,
      spaceBetween: 3
    },
    575: {
      spaceBetween: 24,
      slidesPerView: 2
    },
    767: {
      slidesPerView: 2,
      spaceBetween: 5
    },
    991: {
      slidesPerView: 3
    },
    // -----
    1199: {
      slidesPerView: 3
    },
    1379: {
      slidesPerView: 3
    },
    1639: {
      slidesPerView: 3
    }
  }
};
let swiperHorizontalWideOptions = {
  slidesPerView: 6,
  spaceBetween: 14,
  breakpoints: {
    320: {
      slidesPerView: 1
    },
    410: {
      slidesPerView: 2,
      spaceBetween: 5
    },
    575: {
      spaceBetween: 24,
      slidesPerView: 3
    },
    767: {
      slidesPerView: 3,
      spaceBetween: 5
    },
    991: {
      slidesPerView: 3
    },
    // -----
    1199: {
      slidesPerView: 4
    },
    1379: {
      slidesPerView: 5
    },
    1639: {
      slidesPerView: 6
    }
  }
};

function sliderInit(...items) {
  if ($('.js-swiper_corusel').length) {
    if (items.length > 0) {
      myCategories = $(items).find('.js-swiper_corusel');
    } else {
      myCategories = $('.js-swiper_corusel');
    }

    myCategories.each(function() {
      let options = null;

      switch ($(this).data('size')) {
      case 'middle':
        options = swiperMiddleOptions;
        break;
      case 'wide':
        options = swiperWideOptions;
        break;
      case 'horizontal_middle':
        options = swiperHorizontalMiddleOptions;
        break;
      case 'horizontal_wide':
        options = swiperHorizontalWideOptions;
        break;

      default:
        options = swiperMiddleOptions;
        break;
      }

      let $swiper = new Swiper($(this), {
        slidesPerView: options.slidesPerView,
        spaceBetween: options.spaceBetween,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: options.breakpoints
      });

      mySwipers.push($swiper);
    });
  }
}

$(document).on('turbolinks:load', function() {
  sliderInit();
  $('.js-update_favorite, .js-repost').on('removeFavorites', function() {
    removeSwiperSlide($(this));
  });

  if ($('.js-selection').length) {
    let drafts = $('.js-selection');

    drafts.on('click', '.js-delete_draft', function() {
      if (confirm('Are you sure?')) {
        let $self = $(this),
          isSwiper = $self.parents('.js-swiper_corusel').length,
          url = `/flist/${$self.data('id')}`;

        axios
          .delete(url, {
            headers: {
              'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
          })
          .then(function(response) {
            $self.tooltip('hide');
            if (isSwiper) {
              removeSwiperSlide($self);
            } else {
              $self.closest('.entity').remove();
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    });
  }

  if ($('.js-promo-banners').length) {
    let $sliderModal = $('.js-promo-banners'),
      $nextSlider = $sliderModal.find('.swiper-button-next')[0],
      $previewSlider = $sliderModal.find('.swiper-button-prev')[0];

    bannerSwiper = new Swiper($sliderModal.find('.swiper-container'), {
      speed: 600,
      slidesPerView: 1,
      autoHeight: true,
      navigation: {
        nextEl: $nextSlider,
        prevEl: $previewSlider
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    });
  }
});

$(document).on('turbolinks:before-render', function() {
  if (mySwipers.length > 0) {
    mySwipers.forEach(swiper => {
      swiper.destroy();
    });
  }

  if (bannerSwiper != null && bannerSwiper.length > 0) {
    bannerSwiper.destroy();
  }
});

function removeSwiperSlide(elem) {
  let swiper = elem.closest('.js-swiper_corusel');

  let swiperSlider = mySwipers.find(sw => $(sw.$el).prop('id') === swiper.prop('id')),
    removedSlideIndex = swiperSlider.clickedIndex;

  swiperSlider.removeSlide(removedSlideIndex);

  if (swiperSlider.slides.length === 0) {
    swiperSlider.destroy();
    swiper.remove();
  }
}

$(document).on('pagination:loaded', function(e, ...items) {
  sliderInit(...items);
});
