// .js-repost
const axios = require('axios');

import { i18n } from 'src/javascript/components/i18n';
import showToastr from 'src/javascript/components/flash-message';

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
};

$(document).on('turbolinks:load', function() {
  let is_loading = false;

  if ($('.js-repost').length) {
    $(document).on('click.repost', '.js-repost', function() {
      event.preventDefault();

      if (is_loading) {
        return false;
      }

      let $self = $(this);
      const locale = $('html').attr('lang');

      if ($self.data('type') === 'add') {
        axios({
          method: 'post',
          url: $self.data('url')
        })
          .then(({ data }) => {
            showToastr(data.message, 'success');

            $self
              .data('type', 'remove')
              .data('id', data.id)
              .find('.icon')
              .removeClass('icon_repost_white icon_repost_black')
              .addClass('icon_repost_green');

            $self.attr('data-original-title', i18n[locale]['tooltip_unpost']);
            $('[role="tooltip"]')
              .find('.tooltip-inner')
              .text(i18n[locale]['tooltip_unpost']);

            $self.blur();
          })
          .catch(function(error) {
            // toastr.error(error.message);
            showToastr(error.message, 'error');
          })
          .then(() => (is_loading = false));
      } else {
        if (confirm('Are you sure?')) {
          let isSwiper = $self.parents('.js-swiper_corusel').length,
            isMyProfile = $self.closest('.category_selections').data('my-profile') == true,
            url = `/flist/${$self.data('id')}`;

          axios
            .delete(url)
            .then(({ data }) => {
              showToastr(data.message, 'success');

              $self.attr('data-original-title', i18n[locale]['tooltip_repost']);
              $('[role="tooltip"]')
                .find('.tooltip-inner')
                .text(i18n[locale]['tooltip_repost']);

              $self.blur();

              if (isMyProfile) {
                if (isSwiper) {
                  $self.trigger('removeFavorites');
                } else {
                  $self.closest('.entity').remove();
                }
              } else {
                let icon = $self.closest('.entity').length ? 'icon_repost_white' : 'icon_repost_black';

                $self
                  .data('type', 'add')
                  .data('id', '')
                  .find('.icon')
                  .removeClass('icon_repost_green')
                  .addClass(icon);
              }
            })
            .catch(function(error) {
              showToastr(error.message, 'error');
            })
            .then(() => (is_loading = false));
        }
      }
    });
  }
});

$(document).on('turbolinks:before-render', function() {
  $(document).off('click.repost');
});
