// .js-avatar_edit
import showToastr from 'src/javascript/components/flash-message';

const croppie = require('croppie');
const axios = require('axios');

$(document).on('turbolinks:load', function() {

  let $uploadCrop, rawImg, imageId;

  if ($('.js-avatar_edit').length) {
    // Start upload preview image

    $uploadCrop = $('#upload-demo').croppie({
      viewport: {
        width: 360,
        height: 360
      },
      enforceBoundary: false,
      // enableExif: true
      enableOrientation: true
    });
    $('#cropImagePop').on('shown.bs.modal', function() {
      // alert('Shown pop');
      $uploadCrop
        .croppie('bind', {
          url: rawImg
          // orientation: 6
        })
        .then(function() {
          console.log('bind complete');
        });
    });

    $('.rotate').on('click', function(ev) {
      $uploadCrop.croppie('rotate', parseInt($(this).data('deg')));
    });

    $('.item-img').on('change', function() {
      imageId = $(this).data('id');
      $('#cancelCropBtn').data('id', imageId);
      readFile(this);
    });

    $('#cropImageBtn').on('click', function(ev) {
      $uploadCrop
        .croppie('result', {
          type: 'blob',
          format: 'png'
          // type: 'base64'
          // size: { width: 360, height: 360 }
        })
        .then(function(resp) {
          let objectURL = URL.createObjectURL(resp);

          $('#cropImagePop').modal('hide');

          const formData = new FormData();

          $('.js-avatar-loading').show();

          formData.append('file', resp);
          // points: [topLeftX, topLeftY, bottomRightX, bottomRightY]

          axios
            .post('/user/avatar', formData, {
              params: $uploadCrop.croppie('get')
            })
            .then(function(response) {
              $('#item-img-output').attr({
                src: objectURL,
                srcset: objectURL
              });
              $('#user-menu')
                .find('.avatar')
                .attr({
                  src: objectURL,
                  srcset: objectURL
                });
              showToastr('Avatar has been updated', 'success');
              document.querySelector('.item-img').value = '';
              $('.js-avatar-loading').hide();
            })
            .catch(function(error) {
              showToastr(error.message, 'error');
            });
        });
    });
    // End upload preview image
  }

  function readFile(input) {
    if (input.files && input.files[0]) {
      let reader = new FileReader();

      reader.onload = function(e) {
        $('.upload-demo').addClass('ready');
        $('#cropImagePop').modal('show');
        rawImg = e.target.result;
      };
      reader.readAsDataURL(input.files[0]);
    } else {
      swal('Sorry - you\'re browser doesn\'t support the FileReader API');
    }
  }
});
