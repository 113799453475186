export default function copyToClipboard(link, elem) {
  let container = elem ? $(elem) : $('body');
  let $temp = $('<input>');

  container.append($temp);

  $temp.val(link).select();

  document.execCommand('copy');

  $temp.remove();
}
