let tooltipTemplate = [
  '<div class="tooltip tooltip-green" role="tooltip">',
  '<div class="arrow"></div>',
  '<div class="tooltip-inner">',
  '</div>',
  '</div>'
].join('');

let tooltipTemplateBig = [
  '<div class="tooltip tooltip-big" role="tooltip">',
  '<div class="arrow"></div>',
  '<div class="tooltip-inner">',
  '</div>',
  '</div>'
].join('');

$(document).on('turbolinks:load', function() {
  $(function() {
    $('[data-toggle="tooltip_big"]').tooltip({
      delay: {
        show: 500,
        hide: 0
      },
      html: true,
      template: tooltipTemplateBig
    });
    $('[data-toggle="tooltip"]').tooltip({
      delay: {
        show: 500,
        hide: 0
      }
    });
    $('[data-toggle="tooltip_green"]').tooltip({
      html: true,
      template: tooltipTemplate,
      delay: {
        show: 500,
        hide: 0
      }
    });

    $('[data-toggler="tooltip"]').tooltip({
      delay: {
        show: 500,
        hide: 0
      }
    });
    $('[data-toggler="tooltip_green"]').tooltip({
      html: true,
      template: tooltipTemplate,
      delay: {
        show: 500,
        hide: 0
      }
    });
  });

  $('[data-toggler="tooltip"]').on('click', function() {
    $(this).tooltip('hide');
  });
});

$(document).on('pagination:loaded', function() {
  $('[data-toggle="tooltip_big"]').tooltip({
    delay: {
      show: 500,
      hide: 0
    },
    html: true,
    template: tooltipTemplateBig
  });
  $('[data-toggle="tooltip"]').tooltip({
    delay: {
      show: 500,
      hide: 0
    }
  });
  $('[data-toggle="tooltip_green"]').tooltip({
    html: true,
    template: tooltipTemplate,
    delay: {
      show: 500,
      hide: 0
    }
  });

  $('[data-toggler="tooltip"]').tooltip({
    delay: {
      show: 500,
      hide: 0
    }
  });
  $('[data-toggler="tooltip_green"]').tooltip({
    html: true,
    template: tooltipTemplate,
    delay: {
      show: 500,
      hide: 0
    }
  });
});
