//require('floating-form-labels/dist/floatingFormLabels.min.js');
//require('parsleyjs');
//require('../components/password_validation');


import Sortable from 'sortablejs';

const Choices = require('choices.js');
const pickmeup = require('pickmeup');
const axios = require('axios');

let parsleyForm = null,
  choices = null,
  multichoices = null,
  sortable = null;

$(document).on('turbolinks:load', function() {

  const locale = $('html').attr('lang');

  //console.log('settings!');

  if ($('.professions_filter').length) {
    multichoices = new Choices('.js-multiselect', {
      removeItemButton: true
    });
  }
  if ($('.js-settings').length) {
    let $field = $('.js-ffl'),
      $birthdate = $('.js-birthdate_picker'),
      $select = $('.js-select'),
      $multiselect = $('.js-multiselect');

    if ($select.length) {
      choices = new Choices('.ffl-select', {
        placeholder: true
      });
    }

    if ($('.prof-select').length) {
      field = new Choices('.prof-select', {
        placeholder: true,
        maxItemCount: 3
      });
    }

    if ($multiselect.length) {
      multichoices = new Choices('.js-multiselect', {
        removeItemButton: true
      });
    }

    if ($birthdate.length) {
      const today = new Date();

      pickmeup.defaults.locales['ru'] = {
        days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        daysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']
      };

      pickmeup.defaults.locales['ua'] = {
        days: ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четверг', 'П\'ятница', 'Субота'],
        daysShort: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        daysMin: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
        monthsShort: ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру']
      };

      pickmeup.defaults.locales['es'] = {
        days: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
        daysShort: ["dom.", "lun.", "mart.", "miérc.", "juev.", "viern.", "sáb."],
        daysMin: ["do", "lu", "ma", "mi", "ju", "vi", "sá"],
        months: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
        monthsShort: ["en.", "febr.", "mzo.", "abr.", "my.", "jun.", "jul.", "agto.", "sept.", "oct.", "nov.", "dic."],
      };

      pickmeup('.js-birthdate_picker', {
        default_date: false,
        hide_on_select: true,
        view: 'years',
        min: today.setFullYear(today.getFullYear() - 120),
        max: new Date(),
        format: 'd/m/Y', 
        locale: locale,
        first_day: 1
      });


    }

    $field.floatingFormLabels();

    setTimeout(function() {
      if ($field.find('input:-webkit-autofill').length) {
        $field.find('input:-webkit-autofill').trigger('change');
      }
    }, 200);

    let authForm = $('.js-validate');

    if (authForm.length) {
      parsleyForm = authForm.parsley();
    }

    if ($('.js-sorting_socials').length) {
      let socialList = document.querySelector('.js-sorting_socials');

      sortable = Sortable.create(socialList, {
        handle: '.icon'
      });
    }

    $('.js-flister_request').on('click', function() {
      let $self = $(this);

      $self
        .parent('p')
        .hide()
        .closest('.js-settings')
        .find('form')
        .removeClass('d-none');
    });
  }
});

$(document).on('turbolinks:before-render', function() {
  if (parsleyForm) {
    destroyElements(parsleyForm);

    parsleyForm = null;
  }

  if (choices) {
    destroyElements(choices);

    choices = null;
  }
  if (multichoices) {
    destroyElements(multichoices);

    multichoices = null;
  }

  if ($('.js-birthdate_picker').length) {
    pickmeup('.js-birthdate_picker').destroy();
  }

  if (sortable) {
    destroyElements(sortable);

    sortable = null;
  }

  function destroyElements(arr) {
    if (arr.length) {
      for (let select of arr) {
        select.destroy();
      }
    } else {
      arr.destroy();
    }
  }
});
