import axios from 'axios';
import throttle from 'lodash/throttle';

let is_loading = false;
let $window = $(window);
let screen_height = window.innerHeight;

let paginator = {
  $block: null,
  $content: null,
  $moreBtn: null,
  $pagination: null
};

let paginator_comment = {
  $block: null,
  $content: null,
  $moreBtn: null,
  $pagination: null
};

$(document).on('turbolinks:load', function() {

  //For feed recommedation comments loads by button
    paginator_comment.$block = $('.js-paginated_feed_comment');
    paginator_comment.$content = paginator_comment.$block.find('.js-paginated__content_feed_comment');
    paginator_comment.$moreBtn = paginator_comment.$block.find('.js-paginated__more_feed_comment');
    paginator_comment.$pagination = paginator_comment.$block.find('.js-paginated__pages_feed_comment');

    $(document.body).on('click', '.btn-loadmore_feed_comment', function(event) {
      event.preventDefault();
      paginator_comment.$block = $(this).closest('.js-paginated_feed_comment');
      paginator_comment.$content = paginator_comment.$block.find('.js-paginated__content_feed_comment');
      paginator_comment.$moreBtn = paginator_comment.$block.find('.js-paginated__more_feed_comment');
      paginator_comment.$pagination = paginator_comment.$block.find('.js-paginated__pages_feed_comment');

      loadItems(paginator_comment, 'feed');
    });
    
  if ($('.js-paginated__more a').length) {
    
    paginator.$block = $('.js-paginated');
    paginator.$content = paginator.$block.find('.js-paginated__content');
    paginator.$moreBtn = paginator.$block.find('.js-paginated__more');
    paginator.$pagination = paginator.$block.find('.js-paginated__pages');

    paginator.$moreBtn.on('click', '.btn-loadmore', function(event) {
      event.preventDefault();
      paginator.$block = $(this).closest('.js-paginated');
      paginator.$content = paginator.$block.find('.js-paginated__content');
      paginator.$moreBtn = paginator.$block.find('.js-paginated__more');
      paginator.$pagination = paginator.$block.find('.js-paginated__pages');

      loadItems(paginator);
    });

    // Infinity loading
    if (paginator.$moreBtn.data('type') && paginator.$moreBtn.data('type') == 'infinity') {
      const scrollHandler = throttle(() => {
        if (!is_loading) {
          if (paginator.$moreBtn[0].getBoundingClientRect().top < screen_height) {
            loadItems(paginator);
          }
        }
      }, 250);

      $window.on('scroll.pagination', scrollHandler);
    }
  }
});

$(document).on('turbolinks:before-render', function() {
  is_loading = false;

  if (paginator.$moreBtn != null && paginator.$moreBtn.data('type') == 'infinity') {
    $window.off('scroll.pagination');
  }

  if (paginator_comment.$moreBtn != null && paginator_comment.$moreBtn.data('type') == 'infinity') {
    $window.off('scroll.pagination');
  }

});

function loadItems(paginatorArg, _controller) {
  let $paginator = paginatorArg;
  let request;

  if (is_loading) {
    return false;
  }

  is_loading = true;

  let $btn = $paginator.$moreBtn.find('a'),
    url = $btn.attr('href');

  $btn.prop('disabled', true).addClass('btn-loading');

  request = url + '&ajax=' + Date.now;
  
  if(_controller == 'feed'){
    request = request + "&con=feed";
  }

  axios({
    method: 'GET',
    url: request,
    responseType: 'text',
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
    .then(response => {
      let $html = $(response.data);
      let items, pagination, next;

      if(_controller == 'feed'){
        items = $html.find('.js-paginated__content_feed_comment').html();
        pagination = $html.find('.js-paginated__pages_feed_comment').html();
        next = $html.find('.js-paginated__more_feed_comment').html();
      }else{
        items = $html.find('.js-paginated__content').html();
        pagination = $html.find('.js-paginated__pages').html();
        next = $html.find('.js-paginated__more').html();
      }

     // console.log(response.data);

      let $items = $(items).appendTo($paginator.$content);

      // Show more
      $(document).trigger('pagination:loaded', $items);

      // history.replaceState({ path: url }, $html.find('title').text(), url);

      if ($(next).length) {
        $paginator.$moreBtn.html(next);
        $paginator.$pagination.html(pagination);
        // is_loading = false;
      } else {
        $paginator.$moreBtn.remove();
        $paginator.$pagination.html(pagination);
        $window.off('scroll.pagination');
        // is_loading = false;
        // $window.off("resize.pagination");
      }
    })
    .catch(error => {
       console.log('error', error);

      $paginator.$moreBtn
        .prop('disabled', false)
        .removeClass('btn-loading')
        .text('Try again');

      $window.off('scroll.pagination');

      // window.setTimeout(() => {
      //   is_loading = false;
      // }, 250);
    })
    .then(() => {
      window.setTimeout(() => {
        is_loading = false;
      }, 250);
    });
}
