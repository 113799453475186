import { mixpanel } from 'src/javascript/components/mixpanel';

let getaudio = null,
audiostatus = 'off',
playerControls = null;

function audioPlayerInit(){

    if ($('.player-controls').length) {
      
      playerControls = '.player-controls';

      $("body").delegate(playerControls, 'click touch', function(e){
        
        mixpanel.track("Click play music button");

        e.preventDefault();

        const $self = $(this);
        
        $(playerControls).not(this).each(function() {
          $(this).children('.audio')[0].pause();
          $(this).removeClass('playing');
        });

        getaudio = $self.children('.audio')[0];
        
        if (!$self.hasClass('playing')) {
          if (audiostatus == 'off') {
            $self.addClass('playing');

            getaudio.load();
            getaudio.play();

            audiostatus = 'on';
            return false;
          } else if (audiostatus == 'on') {
            $self.addClass('playing');
            getaudio.play();
          }
        } else if ($self.hasClass('playing')) {
          getaudio.pause();
          $self.removeClass('playing');

          audiostatus = 'on';
        }

        return false;
      });

      $('.audio').on('ended', function() {
       $(playerControls).removeClass('playing');
        audiostatus = 'off';
      }); 
    }
  }


  $(document).on('turbolinks:load', function() {
    $(function() {
      audioPlayerInit();
    });
  });


  $(document).on('turbolinks:before-render', function() {
    $(playerControls).removeClass('playing');
    playerControls = null;
    audiostatus = 'off';
    getaudio = null;
  });

