const axios = require('axios');

import showToastr from 'src/javascript/components/flash-message';
import { i18n } from 'src/javascript/components/i18n';

let is_loading = false;
const locale = $('html').attr('lang');

$(document).on('turbolinks:load', function() {
  if ($('.js-comment-form').length) {
    $(document).on('keydown', '.js-comment', function(e) {
      if (e.keyCode === 13 && e.shiftKey) {
        e.preventDefault();
        submitCommentForm($(this).closest('.js-comment-form'));
      }
    });

    $(document).on('submit.addComment', '.js-comment-form', function(e) {
      e.preventDefault();
      submitCommentForm($(this));
    });

    $(document).on('click.cleanComment', '.js-comment-clear', function(e) {
      e.preventDefault();
      $(this)
        .closest('.js-comment-form')
        .find('.js-comment')
        .val('');
    });

    $(document).on('click.cancelComment', '.js-comment-cancel', function(e) {
      e.preventDefault();

      let $self = $(this);

      $self
        .closest('.comment')
        .find('.text')
        .show()
        .end()
        .find('.js-comment-edit')
        .fadeIn('fast');

      $self
        .closest('.comment-container')
        .find('.js-comment-replay')
        .show();

      console.log($self.closest('.comment-container').find('.js-comment-replay'));

      $self.closest('.js-comment-form').remove();

      // $replaysContainer
      //   .siblings('.comment')
      //   .find('.js-comment-replay')
      //   .show();
    });

    // delete Comment
    $(document).on('click.deleteComment', '.js-comment-delete', function(e) {
      e.preventDefault();

      if (is_loading) {
        return false;
      }

      is_loading = true;

      let $self = $(this),
        commentId = $self.closest('.comment').data('comment-id');

      if (confirm($self.data('message'))) {
        let url = `/comments/${commentId}`;

        axios
          .delete(url)
          .then(({ data }) => {
            $self.closest('.comment-item').remove();

            // showToastr('Your comment removed', 'success');
          })
          .catch(function(error) {
            showToastr(error.message, 'error');
          })
          .then(() => (is_loading = false));
      } else {
        is_loading = false;
      }
    });

    $(document).on('click.editComment', '.js-comment-edit', function(e) {
      e.preventDefault();

      let $self = $(this),
        $comment = $self.closest('.comment'),
        commentId = $comment.data('comment-id'),
        commentText = $comment.find('.text').text();

      let editForm = `<form class="comment-form js-comment-form" data-comment-id="${commentId}" data-type="edit">
                        <div class="ffl-group ffl-group__textarea mb-2">
                          <textarea class="ffl-textarea js-comment" name="comment" rows="4" required="required" maxlength="2000">${commentText}</textarea>
                        </div>
                        <div class="comment-form-actions text-right">
                          <button class="btn btn-link btn-xxs js-comment-cancel" type="button">
                            ${i18n[locale]['button_cancel']}
                          </button>
                          <button class="btn btn-secondary btn-xxs js-comment-btn" type="submit">
                            ${i18n[locale]['button_save']}
                          </button>
                        </div>
                      </form>`;

      $comment
        .find('.comment-content')
        .find('.text')
        .hide();

      $self.fadeOut('fast');

      $(editForm).appendTo($comment.find('.comment-content'));
    });

    // replayComment
    $(document).on('click.replayComment', '.js-comment-replay', function(e) {
      e.preventDefault();

      let $self = $(this),
        $commentContainer = $self.closest('.comment-container'),
        $commentReplays = $commentContainer.find('.comment-replays'),
        $comment = $commentContainer.find('.comment'),
        commentId = $comment.data('comment-id'),
        recommendationId = $comment.data('recommendation-id');

      let replayForm = `<form class="comment-form js-comment-form" data-recommendation-id="${recommendationId}" data-comment-id="${commentId}" data-type="replay">
                        <div class="ffl-group ffl-group__textarea mb-2">
                          <textarea class="ffl-textarea js-comment" name="comment" rows="4" required = "required"></textarea>
                        </div>
                        <div class="comment-form-actions text-right">
                          <button class="btn btn-link btn-xxs js-comment-cancel" type="button">
                            ${i18n[locale]['button_cancel']}
                          </button>
                          <button class="btn btn-secondary btn-xxs js-comment-btn" type="submit">
                            ${i18n[locale]['button_save']}
                          </button>
                        </div>
                      </form>`;

      $self.hide();

      $(replayForm).prependTo($commentReplays);
    });

    // Load replay
    $(document).on('click.loadReplays', '.js-load-replays', function(e) {
      e.preventDefault();

      if (is_loading) {
        return false;
      }

      is_loading = true;

      let $self = $(this),
        $replaysContainer = $self.closest('.comment-replays');

      axios({
        method: 'GET',
        url: $self.prop('href') + '&ajax=' + Date.now(),
        responseType: 'text',
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
        .then(({ data }) => {
          if ($replaysContainer.find('.comment').length) {
            $replaysContainer.find('.comment-actions').remove();
            $replaysContainer.append($(data));
          } else {
            $replaysContainer.empty().html($(data));
          }
        })
        .catch(function(error) {
          showToastr(error.message, 'error');
        })
        .then(() => (is_loading = false));
    });

    // BAN user
    $(document).on('click.banUser', '.js-comment-ban', function(e) {
      e.preventDefault();

      let $comment = $(this).closest('.comment'),
        commentId = $comment.data('comment-id'),
        recommendationId = $comment.data('recommendation-id'),
        ownerId = $comment.data('owner-id'),
        $commentList = $comment.closest('.js-comments-list');

      if (confirm($(this).data('message'))) {
        let url = '/comments/ban_user';

        axios
          .post(url, {
            comment_id: commentId,
            recommendation_id: recommendationId,
            user_id: ownerId
          })
          .then(({ data }) => {
            $commentList.find('.comment[data-owner-id="' + ownerId + '"]').addClass('comment__banned');

            showToastr(data.message, 'warning');
          })
          .catch(function(error) {
            showToastr(error.message, 'error');
          });
      }
    });
  }
});

$(document).on('turbolinks:before-render', function() {
  $(document).off('submit.addComment');
  $(document).off('click.deleteComment');
  $(document).off('click.editComment');
  $(document).off('click.cleanComment');
  $(document).off('click.cancelComment');
  $(document).off('click.replayComment');
  $(document).off('click.loadReplays');
});

function updateComment(form, newComment) {
  let $comment = form.closest('.comment');

  $comment
    .find('.text')
    .html(
      $(newComment)
        .find('.text')
        .html()
    )
    .show()
    .end()
    .find('.js-comment-edit')
    .fadeIn('fast');

  form.remove();
}

function addReplayComment(form, newComment) {
  let $replaysContainer = form.closest('.comment-replays');

  $(newComment).appendTo($replaysContainer);

  form.remove();

  $replaysContainer
    .siblings('.comment')
    .find('.js-comment-replay')
    .show();

  // showToastr('Your comment added', 'success');
}

function submitCommentForm(form) {

  console.log(form);

  if (is_loading) {
    return false;
  }

  is_loading = true;

  let commentForm = form,
    $commentField = commentForm.find('.js-comment'),
    $commentsList = commentForm.parent().find('.js-comments-list'),
    commentFormType = commentForm.data('type');

  commentForm
    .find('.js-comment-btn')
    .prop('disabled', 'disabled')
    .addClass('btn-loading');

  let options = {};

  console.log($commentsList);

  // Form Type (Edit, Replay, Default)
  switch (commentFormType) {
  case 'edit':
    options.method = 'put';
    options.url = `/comments/${commentForm.data('comment-id')}`;
    break;

  case 'replay':
    options.method = 'post';
    options.url = `/flist/${commentForm.data('recommendation-id')}/comments/${commentForm.data('comment-id')}`;
    break;

  default:
    options.method = 'post';
    options.url = `/flist/${commentForm.data('recommendation-id')}/comments`;
    break;
  }

  axios({
    method: options.method,
    url: options.url,

    data: {
      message: $commentField.val()
    }
  })
    .then(({ data }) => {
      switch (commentFormType) {
      case 'edit':
        updateComment(commentForm, data);
        break;

      case 'replay':
        addReplayComment(commentForm, data);
        break;

      default:
        $commentsList.prepend(`<div class="comment-container comment-item fadeOut">${data}</div>`);
        $commentField.val('');
        // showToastr('Your comment added', 'success');
        break;
      }
    })
    .catch(function(error) {
      showToastr(error.message, 'error');
    })
    .then(() => {
      is_loading = false;

      commentForm
        .find('.js-comment-btn')
        .prop('disabled', '')
        .removeClass('btn-loading');
    });
}
