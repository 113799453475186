import addShowMoreLink from 'src/javascript/components/show_more';
import copyToClipboard from 'src/javascript/components/copy_link';
$(document).on('turbolinks:load', function() {
  if ($('#recommendation').length || $('.feed').length) {
    const $recommendationList = $('#recommendation');

    if ($recommendationList.find('.js-show_more').length > 0) {
      $recommendationList.find('.js-show_more').each(function() {
        addShowMoreLink(this);
      });
    }
    $(document).on('click.copy', '.js-copy_link', function() {
      event.preventDefault();

      copyToClipboard($(this).attr('data-href'));
    });
  }
});

$(document).on('turbolinks:before-render', function() {
  $(document).off('click.copy');
});
