$(document).on('turbolinks:load', function() {

    var pwa_modal = $('#PwaInstall');

    if( pwa_modal.length ){

    var button_page = document.querySelector("#install_pwa");
    var button_modal = document.querySelector("#install_pwa_modal");
    
    // Detects if device is on iOS 
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }
    
    // Detects if device is on iOS and browser Safari
    const isIosSafari = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iP(ad|hone|od).+Version\/[\d\.]+.*Safari/i.test(navigator.userAgent);
    }

    //GET Parsing
    $.urlParam = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)')
                          .exec(window.location.href);
        if (results == null) {
             return 0;
        }
        return results[1] || 0;
    }
    
    function MakeUrlRedirectBridge(){
        
        var url_redirect = "ftp://" + window.location.hostname + '/install.html?locale=' + $('html').attr('lang');

        if($.urlParam('utm_source')){
            url_redirect += "&utm_source=" + $.urlParam('utm_source');
        }

        if($.urlParam('utm_medium')){
            url_redirect += "&utm_medium=" + $.urlParam('utm_medium');
        }

        if($.urlParam('utm_campaign')){
            url_redirect += "&utm_campaign=" + $.urlParam('utm_campaign');
        }

        //console.log(url_redirect);
 
        return url_redirect; 
    }
    
    window.addEventListener('beforeinstallprompt', function (e) {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        
        showAddToHomeScreen();
    });

    function showAddToHomeScreen() {

        button_page.addEventListener("click", addToHomeScreen);
        button_modal.addEventListener("click", addToHomeScreen);

    }
    
    function addToHomeScreen() {
        
        if(isIos()){
            runModalPwa();  
            return;  
        }

        if (deferredPrompt) {
            // Show the prompt
            deferredPrompt.prompt();
    
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then(
                function (choiceResult) {
                    if (choiceResult.outcome === 'accepted') {
                        $('.android_pwa').hide(); 
                        $('.default_pwa').hide(); 
                        $('.android_success').show();  
                    }
                    deferredPrompt = null;
            });
        }

    }

    function RedirectSignUp(){
        window.location.href = pwa_modal.attr('data-signup');
        return;
    }

    function runModalPwa(){

        var page_install = $('#page_install');

        const isInStandaloneMode = () =>
        (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
  
        if (isInStandaloneMode()) {
            RedirectSignUp();
        }

        if(isIos()){

            if(!isIosSafari()){
                $('.ios_chrome').show(); //Instruction for not Safarit IOS display
                $('.pwa_top').hide();
                window.location.href = MakeUrlRedirectBridge();
            }
            
            $('.ios_pwa').show();
            button_page.addEventListener("click", runModalPwa);
        }else{
            $('.android_pwa').show();
        }

        pwa_modal.on('show.bs.modal', function (e) {
            page_install.hide();
        });

        pwa_modal.on('hide.bs.modal', function (e) {
            page_install.show();
        });

        pwa_modal.modal();

    }
    
    //Run Modal when page load
    runModalPwa();


    }   
    
}); 