const axios = require('axios');

import showToastr from 'src/javascript/components/flash-message';

// let prevScrollpos = window.pageYOffset;

// function headerAnimation() {
//   let currentScrollPos = window.pageYOffset;

//   if (prevScrollpos >= currentScrollPos) {
//     document.getElementById('header').style.top = '0';
//   } else {
//     document.getElementById('header').style.top = '-70px';
//   }
//   prevScrollpos = currentScrollPos;
// }

$(document).on('turbolinks:load', function() {
  if ($('#navbar').length) {
    // window.addEventListener('scroll', headerAnimation);

    let $navbar = $('#navbar'),
      $userMenu = $('.dropdown_menu-content');

    $navbar.find('#navbarCollapsed').on('show.bs.collapse', function() {
      $navbar.find('.hamburger').addClass('is-active');
    });
    $navbar.find('#navbarCollapsed').on('hide.bs.collapse', function() {
      $navbar.find('.hamburger').removeClass('is-active');
    });

    $navbar.on('click', '.dropdown_menu-toggler', function(event) {
      event.preventDefault();

      let $self = $(this);

      $self.closest('.dropdown_menu-content').toggleClass('open');
      $self.blur();

      if ($self.find('.icon_notifications_active').length) {
        axios({
          method: 'put',
          url: `/profile/${$self.data('id')}/reset_notifications`
        })
          .then(({ data }) => {
            $self
              .find('.icon')
              .removeClass('icon_notifications_active')
              .addClass('icon_notifications');
          })
          .catch(function(error) {
            showToastr(error.message, 'error');
          });
      }
    });

    $(document).on('click.hideUserMenu', function(e) {
      if (!$userMenu.is(e.target) && $userMenu.has(e.target).length === 0 && $userMenu.hasClass('open')) {
        $userMenu.removeClass('open');
      }

      if ($('#navbarCollapsed').hasClass('show')) {
        $('#navbarCollapsed').collapse('hide');
      }
    });
  }
});

$(document).on('turbolinks:before-render', function() {
  $(document).off('click.hideUserMenu');
  // window.removeEventListener('scroll', headerAnimation);
});
