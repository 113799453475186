/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import './application.scss';
// require social-share-button

require('floating-form-labels/dist/floatingFormLabels.min.js');
require('parsleyjs');
require('../src/javascript/components/password_validation');

require('../src/javascript/components/swiper-corusels');
require('../src/javascript/components/header');
require('../src/javascript/components/user-hover');
require('../src/javascript/components/theme-toggler');
require('../src/javascript/components/user-card');
require('../src/javascript/components/category-nav');
require('../src/javascript/components/nav');
require('../src/javascript/components/settings');
require('../src/javascript/components/avatar');
require('../src/javascript/components/follow');
require('../src/javascript/components/top-item');
require('../src/javascript/components/celebrities-filter');
require('../src/javascript/components/list-all');
require('../src/javascript/components/source-modal');
require('../src/javascript/components/audio-player');
require('../src/javascript/components/promo-banner');
require('../src/javascript/components/pagination');
require('../src/javascript/components/feed');
require('../src/javascript/components/likes');
require('../src/javascript/components/user-hover');
require('../src/javascript/components/recommendation_visibility');
require('../src/javascript/components/social-likes');
require('../src/javascript/components/favorites');
require('../src/javascript/components/comments');
require('../src/javascript/components/repost');
require('../src/javascript/components/entity-share');
require('../src/javascript/components/tooltips');


// Search
require('../src/javascript/search_app/search');

import Categories from 'src/javascript/components/categories';

require('popper.js/dist/umd/popper');
require('bootstrap/dist/js/bootstrap');

import { i18n } from 'src/javascript/components/i18n';
import socialLikes from 'social-likes-next';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/serviceworker.js')
    .then(function(registration) {
      console.log('Service worker зарегистрирован:', registration);
    })
    .catch(function(error) {
      console.log('Ошибка при регистрации service worker-а:', error);
    });
} else {
  console.log('Текущий браузер не поддерживает service worker-ы');
}

import {} from 'jquery-ujs';
import { mixpanel } from 'src/javascript/components/mixpanel';

require.context('../images', true);

var Turbolinks = require('turbolinks');

Turbolinks.start();

$(document).on('turbolinks:load', function() {

  $('body').on('click', '.js-url', function(){
    window.location.href = $(this).attr('data-url');
  });

  /*
  mixpanel.track_links(".link-source-single", "Click Affilate Links", {
    "referrer": document.referrer
  });

  mixpanel.track_links(".single-actions-content a", "Click Affilate Links", {
    "referrer": document.referrer
  });

  mixpanel.track_links(".single-services a", "Click Affilate Links", {
    "referrer": document.referrer
  });*/

  if($('html').hasClass("anonymous")){

  let loaded = [];
  let curl = window.location.href;

  loaded = JSON.parse(localStorage.getItem('loaded'));

  if(loaded){
    if (loaded.indexOf(curl) === -1){
      loaded.push(curl);
    }
  }else{
    loaded = [curl];
    localStorage.setItem('auth_modal', 1);
  }

  if(loaded.length <= 3){
    localStorage.setItem('loaded', JSON.stringify(loaded));
  }

  if(loaded.length >= 3 && parseInt(localStorage.getItem('auth_modal')) == 1){
    $('#authModal').modal('show');
    $('#authModal').addClass('auto');
  }

  $('#authModal').on('hide.bs.modal', function () {
    if(this.className.indexOf("auto") >= 0){
      localStorage.setItem('auth_modal', 0);
    }
  });

  }

  const locale = $('html').attr('lang');

  let field = $('.js-ffl');

  field.floatingFormLabels();

  setTimeout(function() {
    if (field.find('input:-webkit-autofill').length) {
      field.find('input:-webkit-autofill').trigger('change');
    }
  }, 200);

  let authForm = $('.js-validate');
  let parsley_form = null;

  if (authForm.length) {
    parsley_form = authForm.parsley({
      excluded: 'input[type=button], input[type=submit], input[type=reset]',
      inputs: 'input, textarea, select, input[type=hidden], :hidden',
    });
  }

 /* window.addEventListener('beforeinstallprompt', e => {
    e.prompt();
  }); */

  if ($('.js-categories').length) {
    Categories();
  }

  if ($('.nav[role="tablist"]').length) {
    $('.nav[role="tablist"] li:first-child a').tab('show');
  }

  if (document.getElementById('shareRecommendation')) {
    socialLikes(document.getElementById('shareRecommendation'));
  }

  $('#authModal').on('show.bs.modal', function (e) {
    
    $('.popover').popover('hide');
    
    if (e.relatedTarget.className.indexOf("log") >= 0){
      $('#register-screen').hide();
      $('#login-screen').show();
      $('#change_login_reg').text(i18n[locale]['register']);
    }else{
      $('#content_auth').show();
      $('#success-register').hide();

      $('#login-screen').hide();
      $('#register-screen').show();
      $('#change_login_reg').text(i18n[locale]['login']);
    }

  });

  $(document).on('turbolinks:before-cache', function() {
    if (parsley_form) {
      parsley_form.destroy();
      parsley_form = null;
    }
  });

  $('form#login-screen').on("ajax:error", function(evt, xhr, status, error){
    var error_messages;

    if(xhr.status == 200){
        window.location.reload();
    }else{
        error_messages = xhr.responseJSON['error'] ? "<div class='alert alert-danger pull-left'>" + xhr.responseJSON['error'] + "</div>" : xhr.responseJSON['errors'] ? $.map(xhr.responseJSON["errors"], function(v, k) {
            return "<div class='alert alert-danger pull-left'>" + k + " " + v + "</div>";
          }).join("") : "<div class='alert alert-danger pull-left'>Unknown error</div>";
        return $('#erorrs_login').html(error_messages);
    }
  });


  $('#change_login_reg').on("click", function(){

    $('#login-screen').toggle();
    $('#register-screen').toggle();

    if($(this).text() == i18n[locale]['register']){
      $(this).text(i18n[locale]['login']);
    } else {
      $(this).text(i18n[locale]['register']);
    }

  });
 
  $('form#register-screen').on("ajax:success", function(){
    $('#content_auth').hide();
    $('#success-register').show();
  });

  $('form#register-screen').on("ajax:error", function(evt, xhr, status, error){

    var error_messages;

     error_messages = xhr.responseJSON['error'] ? "<div class='alert alert-danger pull-left'>" + xhr.responseJSON['error'] + "</div>" : xhr.responseJSON['errors'] ? $.map(xhr.responseJSON["errors"], function(v, k) {
            return "<div class='alert alert-danger pull-left'>" + k + " " + v + "</div>";
          }).join("") : "<div class='alert alert-danger pull-left'>Unknown error</div>";
    
          return $('#erorrs_register').html(error_messages);
 

  });

});


require('../src/javascript/components/pwa_install'); 



 