$(document).on('turbolinks:load', function() {
  if ($('.js-category_nav').length) {
    // window.onresize = navigationResize;
    window.addEventListener('resize', navigationResize, true);
    navigationResize();
  }

  $('.category_nav .more').on('click', function() {
    if (!$(event.target).is('.category_nav-dropdown')) {
      $('.category_nav .category_nav-dropdown').toggleClass('category_nav-dropdown_visible');
    }
  });
});

$(document).on('turbolinks:before-render', function() {
  window.removeEventListener('resize', navigationResize);
});

function navigationResize() {
  let $nav = $('.js-category_nav');

  if (!$nav.length) {
    return false;
  }

  let $navList = $nav.find('.category_nav-list'),
    $navDropdown = $nav.find('.category_nav-dropdown');

  $navList.find('li.more').before($navDropdown.children('li'));

  let $navItemMore = $navList.children('li.more'),
    $navItems = $navList.children('li:not(.more)'),
    navItemWidth = null,
    windowWidth = $nav.width() - 40;

  $navItems.each(function() {
    navItemWidth += $(this).width();
  });

  navItemWidth > windowWidth ? $navItemMore.show() : $navItemMore.hide();

  while (navItemWidth > windowWidth) {
    navItemWidth -= $navItems.last().width();
    $navItems.last().prependTo($navDropdown);
    $navItems.splice(-1, 1);
  }
}
