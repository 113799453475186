$(document).on('turbolinks:load', function() {
  if ($('.js-item_list').length) {
    const $list = $('.js-item_list');

    $list.each(function() {
      let currentList = $(this),
        maxLength = currentList.data('max-items');

      if (currentList.find('.item').length > maxLength) {
        const $allBtn = currentList.find('.js-item_list_all');

        $allBtn.addClass('d-flex');

        $allBtn.on('click', function(event) {
          event.preventDefault();
          $(this).removeClass('d-flex');

          currentList.addClass('list__all');
        });
      }
    });
  }
});
