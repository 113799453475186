import addShowMoreLink from 'src/javascript/components/show_more';

$(document).on('turbolinks:load', function() {
  if ($('.js-user_card').length) {
    let $userCard = $('.js-user_card');

    $userCard.on('click', '.user_card-toggler', function() {
      let $this = $(this);

      $userCard.toggleClass('user_card__collapsed');
      $this.toggleClass('is-active');
    });

    if ($userCard.find('.js-show_more').length) {
      let bio = $userCard.find('.js-show_more');

      addShowMoreLink(bio);
    }
  }
});
