import Swiper from 'swiper';
import { mixpanel } from 'src/javascript/components/mixpanel';


function ModalFeedVideo(selector){

  let $sliderModalFeed = $('.source-modal-feed');

    selector.click(function(e) {
      e.preventDefault();

      mixpanel.track("Run play modal video feed");

      
      $('#video-modal-feed').attr('src', '');

      var video_url = $(this).data("src");

      $sliderModalFeed.on('show.bs.modal', function (event) {
        var modal = $(this);
        $('#video-modal-feed').attr('src', video_url);
      })

    });

    $sliderModalFeed.on('hidden.bs.modal', function(e) {
      $('#video-modal-feed').attr('src', '');
    });


}


$(document).on('turbolinks:load', function() {

  if ($('.js_feed_video').length){
    ModalFeedVideo($('.js_feed_video'));
  }

  if ($('.js-slider-btn').length) {
    let mySwiper,
      $sliderModal = $('.source-modal'),
      $nextSlider = $sliderModal.find('.swiper-button-next')[0],
      $previewSlider = $sliderModal.find('.swiper-button-prev')[0];

    $sliderModal.on('shown.bs.modal', function(e) {
      mySwiper = new Swiper('.swiper-container', {
        speed: 400,
        slidesPerView: 1,
        autoHeight: true,
        navigation: {
          nextEl: $nextSlider,
          prevEl: $previewSlider
        },
        on: {
          slideChange: function() {
            // Only for Youtube video
            if ($sliderModal.prop('id') == 'videoModal') {
              let prevSlide = mySwiper.slides[mySwiper.previousIndex];

              stopVideo(prevSlide);
            }
          }
        }
      });
    });

    $sliderModal.on('hidden.bs.modal', function(e) {
      if ($sliderModal.prop('id') == 'videoModal') {
        let activeSlide = mySwiper.slides[mySwiper.activeIndex];

        stopVideo(activeSlide);
      }

      mySwiper.destroy();
    });
  }

  function stopVideo(elem) {
    $(elem)
      .find('.trailer')[0]
      .contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
  }
});

$(document).on('pagination:loaded', function(e, ...items) {
  ModalFeedVideo($(this).find('.js_feed_video'));
});

