const axios = require('axios');

import showToastr from 'src/javascript/components/flash-message';
import { i18n } from 'src/javascript/components/i18n';

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
};

$(document).on('turbolinks:load', function() {
  let is_loading = false;

  if ($('.js-update_favorite').length) {
    $(document).on('click.toFavorites', '.js-update_favorite', function() {
      event.preventDefault();
      event.stopPropagation();

      if (is_loading) {
        return false;
      }

      let $self = $(this);
      const locale = $('html').attr('lang');

      if ($self.data('type') === 'add') {
        axios({
          method: 'post',
          url: $self.data('url')
        })
          .then(({ data }) => {
            showToastr(data.message, 'success');

            $self
              .data('type', 'remove')
              .data('id', data.id)
              .find('.icon')
              .removeClass('icon_favorites_white icon_favorites_black')
              .addClass('icon_favorites_green');

            $self.attr('data-original-title', i18n[locale]['favourites_remove']);
            $('[role="tooltip"]')
              .find('.tooltip-inner')
              .text(i18n[locale]['favourites_remove']);

            // $self.blur();
            $self.tooltip('hide');
          })
          .catch(error => {
            showToastr(error.response.data.message, 'error');
          })
          .then(() => (is_loading = false));
      } else {
          let isSwiper = $self.parents('.js-swiper_corusel').length,
            isMyFavorites = $self.closest('.category_selections').data('my-favourites') == true,
            url = `/${locale}/flist/favourites/${$self.data('id')}`;

          axios
            .delete(url)
            .then(({ data }) => {
              showToastr(data.message, 'success');
              $self.attr('data-original-title', i18n[locale]['favourites_add']);
              $('[role="tooltip"]')
                .find('.tooltip-inner')
                .text(i18n[locale]['favourites_add']);

              $self.tooltip('hide');

              if (isMyFavorites) {
                if (isSwiper) {
                  $self.trigger('removeFavorites');
                } else {
                  $self.closest('.entity').remove();
                }
              } else {
                let icon = $self.closest('.entity').length ? 'icon_favorites_white' : 'icon_favorites_black';

                $self
                  .data('type', 'add')
                  .data('id', '')
                  .find('.icon')
                  .removeClass('icon_favorites_green')
                  .addClass(icon);
              }
            })
            .catch(error => {
              showToastr(error.response.data.message, 'error');
            })
            .then(() => (is_loading = false));
      }
    });
  }
});

$(document).on('turbolinks:before-render', function() {
  $(document).off('click.toFavorites');
});
