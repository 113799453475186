const axios = require('axios');

import { i18n } from 'src/javascript/components/i18n';

$(document).on('turbolinks:load', function() {
  if ($('.js-follow').length) {
    $(document).on('click.follow', '.js-follow_btn', function() {
      let $self = $(this),
        $container = $self.closest('.js-follow_buttons'),
        $verticalView = $self.parents('.follower').hasClass('follower__vertical'),
        isPopularPeople = $container.closest('.popular_item').length,
        isSinglePage =  $container.closest('.single_page').length;

      const locale = $('html').attr('lang');

      $self.prop('disabled', true);

      console.log($self.data('url'));

      axios({
        method: 'post',
        url: $self.data('url'),
        cache: false
      })
        .then(function(response) {
        
          if (isPopularPeople || isSinglePage) {

          $('[role="tooltip"]').tooltip('dispose');

          $container.html(generateButtonSimple(response.data));

          $('[data-toggle="tooltip"]').tooltip();

          } else {
            $container.html(generateButton(response.data, $container.hasClass('js-follow'), $verticalView));

            $container.addClass('on-hold');
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .then(() =>
          $container.on('mouseout', function() {
            $container.removeClass('on-hold');
          })
        );
    });
  }
  function generateButton(data, isSidebar, isVerticalView) {
    let followBtn = null,
      buttonSizeClass = 'btn-xs';

    // Check if SiderBar button
    if (isSidebar) {
      buttonSizeClass = 'btn-block btn-sm';
    }
    if (isVerticalView) {
      buttonSizeClass = 'btn-sm';
    }

    const locale = $('html').attr('lang');

    console.log('i18n', i18n[locale]['follow']);

    if (data.is_following) {
      followBtn = `<button class="btn btn-outline-secondary following js-follow_btn ${buttonSizeClass}"
          data-url="${data.url}" type="button">${i18n[locale]['following']}</button>
          <button class="btn btn-outline-danger js-follow_btn unfollow ${buttonSizeClass}"
          data-url="${data.url}" type="button">${i18n[locale]['unfollow']}</button>`;
    } else {
      followBtn = `<button class="btn btn-primary js-follow_btn ${buttonSizeClass}"
          data-url="${data.url}" type="button">${i18n[locale]['follow']}</button>`;
    }

    return followBtn;
  }

  function generateButtonSimple(data) {
    let followBtn = null;
  
    const locale = $('html').attr('lang');
  
    if (data.is_following) {
      followBtn = `<button class="js-follow_btn btn-clear" data-url="${data.url}" type="button">
          <div class="icon icon_unfollow" data-placement="bottom" data-toggle="tooltip" title="${i18n[locale]['following']}"></div>
          </button>`;
    } else {
      followBtn = `<button class="js-follow_btn btn-clear" data-url="${data.url}" type="button">
          <div class="icon icon_follow" data-placement="bottom" data-toggle="tooltip" title="${i18n[locale]['follow']}"></div>
          </button>`;
    }
  
    return followBtn;
  }

});

$(document).on('turbolinks:before-render', function() {
  $(document).off('click.follow');
});
