export default function addShowMoreLink(elem) {
  const $self = $(elem),
    quoteText = $self.find('.js-text'),
    quoteTextCached = $self.find('.js-text').text(),
    $toggleBtn = $self.find('.js-show_more-btn');

  let limit = $self.data('length') ? $self.data('length') : 160;

  let mql = window.matchMedia('(min-width: 1640px)');

  if (mql.matches) {
    limit = limit + 80;
  }

  if (quoteText.text().length > limit) {
    let truncatedText =
      $.trim(quoteText.text())
        .substring(0, limit)
        .split(' ')
        .slice(0, -1)
        .join(' ') + '... ';

    quoteText.css('white-space', 'normal');
    quoteText.text(truncatedText);

    $toggleBtn.show();

    $toggleBtn.on('click', function() {
      $(this).hide();
      quoteText.css('white-space', 'pre-wrap');
      quoteText.text(quoteTextCached);
    });
  }
}
