import addShowMoreLink from 'src/javascript/components/show_more';

// let maxLength = 240,
//   mql = window.matchMedia('(max-width: 767.98px)');

// if (mql.matches) {
//   maxLength = 120;
// }
let selectedFilters = [];
var orig_height;

function FilterSubmit(){
  selectedFilters = [];
  $.each($('input.feed-filter_btn:checked'), function() {
    selectedFilters.push($(this).val());
    $('#filterData').val(selectedFilters.join());
    $(this).prop('disabled', true);
  });

  $('.feed-filter_form').submit();
}

$(document).on('turbolinks:load', function() {
  if ($('.feed').length) {
    const $feed = $('.feed');

    if ($feed.find('.js-show_more').length > 0) {
      $feed.find('.js-show_more').each(function() {
        addShowMoreLink(this);
      });
    }
  }

  $('#feedFilter').on('show.bs.collapse', function() {
    $('.feed-filter_blur').addClass('blured');
    $('body').addClass('off-scroll');
  });
  $('#feedFilter').on('hide.bs.collapse', function() {
    $('.feed-filter_blur').removeClass('blured');
    $('body').removeClass('off-scroll');
  });

  $.each($('input.feed-filter_btn:checked'), function() {
    selectedFilters.push($(this).val());
  });

  $('button.feed-filter-submit').click(function() {
    FilterSubmit();
  });


  $(".feed-filter_expand").click(function() { 

    if (!$(this).hasClass("expanded")){
      orig_height = $(".feed-filter_group").outerHeight();

      $(".feed-filter_group").animate({height: $(".feed-filter_group").get(0).scrollHeight}, 500 );
      $(this).addClass("expanded");
    }
    else {
        $(".feed-filter_group").animate({"height": orig_height, "overflow": ""}, 500 );
        $(this).removeClass("expanded");
    }

  });

  $('button.feed-filter-clear').click(function() {
    $.each($('input.feed-filter_btn:checked'), function() {
      $(this)[0].checked = false;
     // console.log($(this));
    });
  });
});

$(document).on('pagination:loaded', function(e, ...items) {
  $(items).each(function() {
    addShowMoreLink($(this).find('.js-show_more'));
  });
});

// Minimalize menu when screen is less than 768px
$(window).bind("resize", function () {
  if (window.innerWidth < 769) {
    $(".feed-filter_group").css({"height": "", "overflow": ""});
    orig_height = $(".feed-filter_group").outerHeight();
    if ($('.feed-filter_expand').hasClass("expanded")){
      $('.feed-filter_expand').removeClass("expanded");
    }
  } 
});
