const axios = require('axios');

import showToastr from 'src/javascript/components/flash-message';
import { i18n } from 'src/javascript/components/i18n';

$(document).on('turbolinks:load', function() {
  let is_loading = false;

  if ($('.js-visibility').length) {
    $(document).on('click.visibility', '.js-visibility', function() {
      event.preventDefault();

      if (is_loading) {
        return false;
      }

      let $self = $(this);
      const locale = $('html').attr('lang');

      axios({
        method: 'put',
        url: $self.data('url')
      })
        .then(({ data }) => {
          $self.data('url', data.url);
          $self.find('.icon').attr('data-type', data.is_visibility ? 'visibile' : 'hidden');
          $self.attr(
            'data-original-title',
            data.is_visibility ? i18n[locale]['tooltip_public'] : i18n[locale]['tooltip_private']
          );
          $('[role="tooltip"]')
            .find('.tooltip-inner')
            .text(data.is_visibility ? i18n[locale]['tooltip_public'] : i18n[locale]['tooltip_private']);
          showToastr(data.message, 'success');
        })
        .catch(function(error) {
          showToastr(error.message, 'error');
        })
        .then(() => (is_loading = false));
    });
  }
});

$(document).on('turbolinks:before-render', function() {
  $(document).off('click.visibility');
});
