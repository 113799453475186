import socialLikes from 'social-likes-next';

$(document).on('turbolinks:load', function() {
  if (document.querySelectorAll('.js-social-likes').length > 0) {
    let socialItems = document.querySelectorAll('.js-social-likes');

    for (const social of socialItems) {
      socialLikes(social);
    }
  }

  if (document.querySelectorAll('.js-social-likes_mob').length > 0) {
    $(document).on('click.share', '.js-social-likes_mob', function() {
      let $self = $(this);

      if (navigator.share) {
        navigator
          .share({
            title: $self.data('title'),
            url: $self.data('url')
          })
          .then(() => {
            console.log('Thanks for sharing!');
          })
          .catch(console.error);
      }
    });
  }
});

$(document).on('pagination:loaded', function(e, ...items) {

  $(items).each(function() {
    if ( $(this).hasClass('feed_entity') || $(this).hasClass('top__card') ) {
      socialLikes(this.querySelector('.js-social-likes'));
    }
  });
});

$(document).on('turbolinks:before-render', function() {
  $(document).off('click.share');
});
