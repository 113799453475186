import axios from 'axios';
import debounce from 'lodash/debounce';
function toggleTooltip() {
  if (document.querySelector('.tooltip')) {
    document.querySelector('.tooltip').remove();
  }
  $('#searchApp').tooltip('toggleEnabled');
}
export default {
  props: ['search_query'],

  data() {
    return {
      showFullForm: false,

      latestSearchQueries: [],
      isShowLatestSearch: false,

      searchQueryString: null,

      suggestionList: []
    };
  },

  watch: {
    showFullForm: function() {
      toggleTooltip();
    }
  },

  mounted() {
    this.$i18n.locale = document.querySelector('html').getAttribute('lang');

    if (localStorage.getItem('flistLatestSearch')) {
      this.latestSearchQueries = JSON.parse(localStorage.getItem('flistLatestSearch'));
    }
    if (this.search_query) {
      this.searchQueryString = this.search_query;

      // this.showFullForm = true;
    }
  },

  methods: {
    searchToggler() {
      this.showFullForm = true;

      this.$refs.searchField.focus();

      if (localStorage.getItem('flistLatestSearch')) {
        this.showLatestSearch();
      }
    },

    showLatestSearch: debounce(function() {
      this.isShowLatestSearch = true;
    }, 300),

    inputedSearchString: debounce(function() {
      if (this.searchQueryString.length > 2) {
        this.getSuggestions();
      }
    }, 300),

    putInLatestSearch(suggestion) {
      if (this.searchQueryString.length) {
        const max = 3;

        let newSearchStr = suggestion ? suggestion : this.searchQueryString;

        this.latestSearchQueries.unshift(newSearchStr);

        if (this.latestSearchQueries.length > max) {
          this.latestSearchQueries = this.latestSearchQueries.slice(0, max);
        }

        localStorage.setItem('flistLatestSearch', JSON.stringify(this.latestSearchQueries));
      }
    },

    hideSearch() {
      if (this.showFullForm) {
        this.isShowLatestSearch = false;
        this.showFullForm = false;
      }
    },

    sendLatestQuery(query) {
      axios
        .get('/search/suggestions', {
          params: {
            query: query
          }
        })
        .then(({ data }) => {
          // console.log('data');
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    getSuggestions() {
      axios
        .get(`/search/suggestions?query=${this.searchQueryString}`)
        .then(({ data }) => {
          this.suggestionList = data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    submitSearch() {
      this.putInLatestSearch();
    }
  },

  components: {}
};
