const axios = require('axios');
import { messages } from '@/recommendations_app/utils/locales';


$(document).on('turbolinks:load', function() {
  let is_loading = false;
  const locale = $('html').attr('lang');

  $(document).on('click.like', '.js-like', function(event) {
    event.preventDefault();

    if (is_loading) {
      return false;
    }

    is_loading = true;

    let $self = $(this),
      $qty = $self.siblings('.count');

    axios({
      method: 'put',
      url: $self.data('url')
    })
      .then(({ data }) => {
        $self.data('url', data.url);
        $self.find('.icon').attr('data-type', data.is_following ? 'fill' : 'empty');

        if (data.is_following) {
          $qty.text(parseInt($qty.text()) + 1);
        } else {
          $qty.text(parseInt($qty.text()) - 1);
        }

        // If FUll view of likes
        if ($self.parents('.item-stats__full').length) {
          let $likeContainer = $self.parents('.stats__likes'),
            followers = $likeContainer.find('.stat-users'),
            user = data.user;

          if (data.is_following) {
            let newFollower = `<div class="follower" data-uid="${user.id}"><img srcset="${user.avatar.avatar_1x.url} 2x" alt="${user.full_name}" class="avatar" src="${user.avatar.thumb.url}"></div>`;

            followers.prepend(newFollower);
          } else {
            let removedFollower = followers.find(`.follower[data-uid=${user.id}]`);

            if (removedFollower.length) {
              removedFollower.remove();
            }
          }

          // Update label (pluralize)
          if (parseInt($qty.text()) < 3) {
            let qtyLabel = messages[locale]['like']['many'];

            switch (parseInt($qty.text())) {
            case 0:
              qtyLabel = messages[locale]['like']['other'];
              break;
            case 1:
              qtyLabel = messages[locale]['like']['one'];
              break;
            default:
    
              qtyLabel = messages[locale]['like']['few'];
            }

            $likeContainer.find('.value').text(qtyLabel);
          }
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .then(() => (is_loading = false));
  });
});

$(document).on('turbolinks:before-render', function() {
  $(document).off('click.like');
});
