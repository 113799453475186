const axios = require('axios');

$(document).on('turbolinks:load', function() {
  if ($('.js-theme_toggler').length) {
    const $toogler = $('.js-theme_toggler');

    $toogler.on('change', '#themeToggler', function() {
      $('html').toggleClass('theme--default theme--dark');

      axios.post('/my_profile/set_theme', {
        theme: $(this).prop('checked') ? 'dark' : 'default'
      });
      // .then(function(response) {
      //   // console.log(response);
      // })
      // .catch(function(error) {
      //   // console.log(error);
      // });
    });
  }
});
