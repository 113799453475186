function PasswordProgress(){
    
    $('#popover-password').show();
    
    var count_errors = $('span.password_validation .parsley-errors-list li').length;
    var $barline = $('#password-barline');
    var $result = $('.result-progress');

    if(count_errors == 6){
      $barline.attr('class', 'progress-bar bg-danger').css('width', '0%');
      $result.text( $result.data('message-empty') );
    }else if(count_errors == 5){
      $barline.attr('class', 'progress-bar bg-danger').css('width', '10%');
      $result.text( $result.data('message-danger') );
    }else if(count_errors == 4){
      $barline.attr('class', 'progress-bar bg-danger').css('width', '25%');
      $result.text( $result.data('message-danger') );
    }else if(count_errors == 3){
      $barline.attr('class', 'progress-bar bg-danger').css('width', '50%');
      $result.text( $result.data('message-danger') );
    }else if(count_errors == 2){
      $barline.attr('class', 'progress-bar bg-warning').css('width', '75%');
      $result.text( $result.data('message-warning') );
    }else if(count_errors == 1){
      
      if($('li.parsley-maxlengthcustom').length == 1){
        $('#popover-password').hide();
      }else{
        $barline.attr('class', 'progress-bar bg-warning').css('width', '90%');
        $result.text( $result.data('message-warning') );
      }

    }else{
      $barline.attr('class', 'progress-bar bg-success').css('width', '100%');
      $result.text( $result.data('message-success') );
    }

}

$(document).on('turbolinks:load', function() {

let authForm = $('.js-validate');
let passwordField = $('#user_password');

if (authForm.length && passwordField.length){
    
 // console.log('components password validation!');

  $(".toggle-password").click(function() {

    $(this).toggleClass("open");

    var input = $($(this).attr("toggle"));
    
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }

  });

//sort li errors user[password] by priority and custom error to input 
$('#user_password').parsley().on('field:error', function() {

    $('.parsley-required').attr('data-sort', 1); 
    $('.parsley-minlengthcustom').attr('data-sort', 2); 
    $('.parsley-maxlengthcustom').attr('data-sort', 2); 
    $('.parsley-uppercase').attr('data-sort', 3); 
    $('.parsley-lowercase').attr('data-sort', 4); 
    $('.parsley-number').attr('data-sort', 5); 
    $('.parsley-special').attr('data-sort', 6);

    $("span.password_validation .parsley-errors-list li").sort(sort_li).appendTo('span.password_validation .parsley-errors-list');
    function sort_li(a, b) {
      return ($(b).data('sort')) < ($(a).data('sort')) ? 1 : -1;
    }

    if( $('#custom_for_password').length <= 0){
      this.$element.parent().append('<ul id="custom_for_password" class="parsley-errors-list filled" ><li>' + this.$element.attr('data-parsley-required-message') + '</li></ul>');
    }

});

$('#user_password').parsley().on('field:success', function() {
  $('#custom_for_password').remove();
});


//After field validation error or success
$('#user_password').parsley().on('field:validated', function() {
  PasswordProgress();  
});



  //min lenght custom parsley validator
 /* window.Parsley.addValidator('required', {
    requirementType: 'boolean',
    validateString: function(value) {
      if(value == ''){
        return false;
      }
    },
    priority: 10
  });*/

//min lenght custom parsley validator
window.Parsley.addValidator('minlengthcustom', {
  requirementType: 'number',
  validateString: function(value, requirement) {
    let check = value.length >= requirement;
    if(check == false){
      return check;
    }
  },
  priority: 10
});

//max lenght custom parsley validator
window.Parsley.addValidator('maxlengthcustom', {
  requirementType: 'number',
  validateString: function(value, requirement) {
    let check = value.length <= requirement;
    if(check == false){
      return check;
    }
  },
  priority: 10
});


  //has number custom parsley validator
  window.Parsley.addValidator('number', {
    requirementType: 'number',
    validateString: function(value, requirement) {
      var numbers = value.match(/[0-9]/g) || [];
      let check = numbers.length >= requirement;
      if(check == false){
        return check;
      } 
    },
    priority: 10
  });

  //has special char custom parsley validator
  window.Parsley.addValidator('special', {
    requirementType: 'number',
    validateString: function(value, requirement) {
      var specials = value.match(/[^a-zA-Z0-9]/g) || [];
      let check = specials.length >= requirement;
      if(check == false){
        return check;
      }
    },
    priority: 10
  });

  //has uppercase custom parsley validator
  window.Parsley.addValidator('uppercase', {
    requirementType: 'number',
    validateString: function(value, requirement) {
      var uppercases = value.match(/[A-Z]/g) || [];
      let check = uppercases.length >= requirement;
      if(check == false){
        return check;
      }
    },
    priority: 10
  });
  
    //has lowercase custom parsley validator
    window.Parsley.addValidator('lowercase', {
      requirementType: 'number',
      validateString: function(value, requirement) {
        var lowecases = value.match(/[a-z]/g) || [];
        let check = lowecases.length >= requirement;
        if(check == false){
          return check;
        }
      },
      priority: 10
    });

}

});
