const i18n = {
  en: {
    following: 'Following',
    unfollow: 'Unfollow',
    follow: 'Follow',
    tooltip_private: 'Private',
    tooltip_public: 'Public',
    favourites_remove: 'Remove from Favorites',
    favourites_add: 'Add to Favorites',
    tooltip_repost: 'Rеpost',
    tooltip_unpost: 'Unpost',
    button_save: 'Save',
    button_cancel: 'Cancel',
    loading: 'Loading ...',
    login: 'Already have an account? Sign in',
    register: 'No account? Sign up'
  },

  ru: {
    following: 'Вы подписаны',
    unfollow: 'Отписаться',
    follow: 'Подписаться',
    tooltip_private: 'Только для меня',
    tooltip_public: 'Виден для всех',
    favourites_remove: 'Удалить из закладок',
    favourites_add: 'Добавить в закладки',
    tooltip_repost: 'Репост',
    tooltip_unpost: 'Удалить пост',
    button_save: 'Оставить',
    button_cancel: 'Отмена',
    loading: 'Загрузка ...',
    login: 'Уже есть аккаунт? Войти',
    register: 'Нет аккаунта? Зарегистрироваться'
  },

  ua: {
    following: 'Ви підписані',
    unfollow: 'Відписатись',
    follow: 'Підписатись',
    tooltip_private: 'Тільки для мене',
    tooltip_public: 'Доступний для всіх',
    favourites_remove: 'Видалити з закладок',
    favourites_add: 'Додати в закладки',
    tooltip_repost: 'Репост',
    tooltip_unpost: 'Видалити репост',
    button_save: 'Залишити',
    button_cancel: 'Відміна',
    loading: 'Завантаження ...',
    login: 'Вже є аккаунт? Увійти',
    register: 'Досі немає аккаунту? Зареєструватись'
  },
  es: {
    following: 'Siguendo',
    unfollow: 'Dejar de seguir',
    follow: 'Seguir',
    tooltip_private: 'Privado',
    tooltip_public: 'Publico',
    favourites_remove: 'Quitar de Favoritos',
    favourites_add: 'Añadir a Favoritos',
    tooltip_repost: 'Rеpost',
    tooltip_unpost: 'Deshacer el Repost',
    button_save: 'Guardar',
    button_cancel: 'Cancelar',
    loading: 'Cargando ...',
    login: '¿Ya tienes cuenta? Accede',
    register: '¿No tienes cuanta aún? Regístrate'
  },
};

export { i18n };
