export default function categories() {
  let $categoryList = $('.js-categories'),
    $form = $('.js-form'),
    $info = $('.js-info');

  $categoryList.on('click', '.js-category', function() {
    let $category = $(this),
      $checkBox = $category.find('.js-check');

    if ($checkBox.prop('checked') === true) {
      $checkBox.prop('checked', false);
      $category.removeClass('category__selected');
    } else {
      $category.addClass('category__selected');
      $checkBox.prop('checked', true);
    }

    let counter = $categoryList.find('input:checked').length;

    if (counter <= 3) {
      currentInfoMessage(counter);
    }
  });

  $form.on('submit', function(event) {
    if ($form.find('input:checked').length >= 3) {
      $form.find('.info').removeClass('text-danger');
    } else {
      event.preventDefault();
      $form.find('.info').addClass('text-danger');
    }
  });

  function currentInfoMessage(counter) {
    $info.find('.info').addClass('d-none');

    $($info.find('.info')[counter]).removeClass('d-none');
  }
}
