// Ready translated locale messages
export const messages = {
  en: {
    header: {
      statuses: {
        saving: 'Saving to Draft...',
        saved: 'Saved to Draft'
      },
      search: {
        tooltip_search: 'Search here...',
        latest_search: 'Latest search'
      },
      new_flist: 'New FLIIST',
      publish: 'Publish',
      close_creation: 'Close Creation'
    },
    content: {
      category: {
        title: 'Choose your category',
        placeholder: '// Choose your category here...'
      },
      flist: {
        title: 'Add a {name} to your FLIIST',
        manualy: 'If you can’t find anything, then add <strong>manually</strong>.',
        not_found: 'We found nothing. Please try another name or paste a link...',
        not_found_only_link: 'Please paste a link...',
        you_added: 'Added:',
        added: {
          Movies: 'movie | movies'
        },
        private: 'Private',
        category: 'Category:',
        create_list: 'Create your list',
        recommendation_cover: 'Recommendation Cover',
        recommendation_title: 'Your title',
        slug: 'Custom slug', 
        recommendation_title_errors: {
          empty: 'Please enter title',
          size: 'Title must be min 3, max 100 characters.'
        },
        quote_placeholder: 'YOUR QUOTE',
        cancel: 'Cancel',
        save: 'Save',

        suggestion: {
          Movie: 'Genres',
          Book: 'Author',
          Song: 'Artist',
          Game: 'Platform',
          Good: 'Description',
          TvShow: 'Genres',
          Person: 'Description',
          MobileApp: 'Description',
          Podcast: 'Description',
          Clip: 'Description',
          CommonItem: 'Description',
          Article: 'Description',
          Website: 'Description',
          Software: 'Description',
          InstagramChannel: 'Description',
          TelegramChannel: 'Description',
          YoutubeChannel: 'Description',
          Brand: 'Description',
          Gadget: 'Description',
          Place: 'Description',
          Country: 'Description',
          City: 'Description',
          Restaurant: 'Description',
          Bar: 'Description',
          Cafe: 'Description',
          Cuisine: 'Description',
          Beauty: 'Description',
          Fashion: 'Description',
          Training: 'Description',
          Service: 'Description',
          Car: 'Description',
          Hobby: 'Description',
          Event: 'Description',
          Sport: 'Description',
          Practice: 'Description',
          Hotel: 'Description'
        }
      }
    },
    confirm: {
      title: 'Please confirm your action',
      text: 'You are going to change category and lose your current progress.',
      change: 'Change',
      cancel: 'Cancel'
    },
    category_name: {
      single: {
        Movie: 'movie',
        Book: 'book',
        Song: 'song',
        Game: 'game',
        Good: 'goods',
        MobileApp: 'mobile app',
        Podcast: 'podcast',
        Person: 'person',
        Clip: 'video',
        CommonItem: 'other',
        TvShow: 'TV show',
        Article: 'article',
        Website: 'website',
        Software: 'software',
        InstagramChannel: 'Instagram channel',
        TelegramChannel: 'Telegram channel',
        YoutubeChannel: 'Youtube channel',
        Brand: 'brand',
        Gadget: 'gadget',
        Place: 'place',
        Country: 'country',
        City: 'city',
        Restaurant: 'restaurant',
        Bar: 'bar',
        Cafe: 'cafe',
        Cuisine: 'cuisine',
        Beauty: 'beauty',
        Fashion: 'fashion',
        Training: 'learning',
        Service: 'service',
        Car: 'car',
        Hobby: 'hobby',
        Event: 'event',
        Sport: 'sport',
        Practice: 'practice',
        Hotel: 'hotel'
      },
      pluralize: {
        Movie: '0 movies | movie | movies | movies',
        Book: '0 books | book | books | books',
        Song: '0 songs | song | songs | songs',
        Game: '0 games | game | games | games',
        Good: '0 goods | goods | goods | goods',
        Restaurant: '0 restaurants | restaurant | restaurants | restaurants',
        MobileApp: '0 mobile apps | mobile app | mobile apps | mobile apps',
        Podcast: '0 podcasts | podcast | podcasts | podcasts',
        Person: '0 persons | person | persons | persons',
        Clip: '0 videos | video | videos | videos',
        CommonItem: '0 goods | goods | goods | goods',
        TvShow: '0 TV shows |  TV show |  TV shows | TV shows',
        Article: '0 articles | articles | articles | articles',
        Website: '0 website | websites | websites | websites',
        Software: '0 software | software | software | software',
        InstagramChannel: '0 Instagram pages | Instagram page | Instagram pages | Instagram pages',
        TelegramChannel: '0 Telegram channels | Telegram channel | Telegram channels | Telegram channels',
        YoutubeChannel: '0 Youtube channels | Youtube channel | Youtube channels | Youtube channels',
        Brand: '0 brands | brand | brands | brands',
        Gadget: '0 gadgets | gadget | gadgets | gadgets',
        Place: '0 places | place | places | places',
        Country: '0 countries |  country |  countries | countries',
        City: '0 cities |  city |  cities | cities',
        Restaurant: '0 restaurants |  restaurant |  restaurants | restaurants',
        Bar: '0 bars |  bar |  bars | bars',
        Cafe: '0 cafes |  cafe |  cafes | cafes',
        Cuisine: '0 cuisine |  cuisine |  cuisine | cuisine',
        Beauty: '0 beauty |  beauty |  beauty | beauty',
        Fashion: '0 fashions |  fashion |  fashions | fashions',
        Training: '0 learnings |  learning |  learnings | learnings',
        Service: '0 services |  service |  services | services',
        Car: '0 cars |  car |  cars | cars',
        Hobby: '0 hobbies |  hobby |  hobbies | hobbies',
        Event: '0 events |  event |  events | events',
        Sport: '0 sports |  sport |  sports | sports',
        Practice: '0 practices |  practice |  practices | practices',
        Hotel: '0 hotels |  hotel |  hotels | hotels'
      }
    },
    item: {
      type_quote: 'Type your quote here',
      add: 'Add',
      edit: 'Edit',
      your_quote: 'Your Quote',
      quote_link: 'QUOTE LINK',
      type_tag: 'ADD TAG (Press Enter)',
      buy_link: 'BUY LINK',
      add_quote_link: 'Add Quote Link',
      add_tags: 'Add Tags',
      add_buy_link: 'Add Buy Link',
      invalidLink: 'Please enter a valid link',
      cancel: 'Cancel',
      save: 'Save'
    },
    item_by_link: {
      preview: 'Preview',
      editor: 'Editor',
      title: 'Title ',
      description: 'Description',
      director: 'DIRECTOR',
      actors: 'ACTORS',
      duration: 'DURATION',
      duration_label: 'DURATION (minutes)',
      year: 'YEAR',
      geners: 'GENRES',
      album_name: 'ALBUM NAME',
      singer: 'SINGER',
      author: 'AUTHOR',
      platform: 'PLATFORM'
    },
    search: {
      placeholder: 'Search here or paste a link',
      placeholder_by_link: 'INSERT A LINK HERE...',
      one_more: 'Add 1 more'
    },
    like:{
      one: "like",
      few: "likes",
      many: "likes",
      other: "likes"
    }
  },
  //-------------- RU ----------------
  ru: {
    header: {
      statuses: {
        saving: 'Сохраняется...',
        saved: 'Сохранено'
      },
      search: {
        tooltip_search: 'Начните поиск...',
        latest_search: 'Последние результаты'
      },
      new_flist: 'Новый FLIIST',
      publish: 'Опубликовать',
      close_creation: 'Закрыть'
    },
    content: {
      category: {
        title: 'Выберите категорию',
        placeholder: '// Выберите свою категорию...'
      },
      flist: {
        title: 'Добавьте {name} в свой FLIIST',
        manualy: 'Не нашли, что искали? Попробуйте <strong>добавить вручную</strong>!',
        not_found: 'Ничего не найдено. Попробуйте другое название или вставьте ссылку на ресурс...',
        not_found_only_link: 'Вставьте ссылку на ресурс...',
        you_added: 'Добавлено:',
        added: {
          Movies: 'фильм | фильма | фильмов'
        },
        private: 'Приватный',
        category: 'Категория:',
        create_list: 'Ваш новый FLIIST',
        recommendation_cover: 'Обложка',
        recommendation_title: 'Назовите свой FLIIST',
        slug: 'Кастомная ссылка', 
        recommendation_title_errors: {
          empty: 'Назовите свой FLIIST',
          size: 'Название должно быть от 3 до 100 символов.'
        },
        quote_placeholder: '// НАПИШИТЕ КОММЕНТАРИЙ К ЭТОМУ FLIIST',
        cancel: 'Закрыть',
        save: 'Сохранить',

        suggestion: {
          Movie: 'Жанр',
          Book: 'Автор',
          Song: 'Музыкант',
          Game: 'Платформа',
          Good: 'Описание',
          TvShow: 'Жанр',
          Person: 'Описание',
          MobileApp: 'Описание',
          Podcast: 'Описание',
          Clip: 'Описание',
          CommonItem: 'Описание',
          Article: 'Описание',
          Website: 'Описание',
          Software: 'Описание',
          InstagramChannel: 'Описание',
          TelegramChannel: 'Описание',
          YoutubeChannel: 'Описание',
          Brand: 'Описание',
          Gadget: 'Описание',
          Place: 'Описание',
          Country: 'Описание',
          City: 'Описание',
          Restaurant: 'Описание',
          Bar: 'Описание',
          Cafe: 'Описание',
          Cuisine: 'Описание',
          Beauty: 'Описание',
          Fashion: 'Описание',
          Training: 'Описание',
          Service: 'Описание',
          Car: 'Описание',
          Hobby: 'Описание',
          Event: 'Описание',
          Sport: 'Описание',
          Practice: 'Описание',
          Hotel: 'Описание'
        }
      }
    },
    confirm: {
      title: 'Вы уверены, что хотите начать заново?',
      text: 'Ваш текущий прогресс сохранен в черновике. Вы хотите начать сначала?',
      change: 'Начать Заново',
      cancel: 'Отмена'
    },
    category_name: {
      single: {
        Movie: 'фильм',
        Book: 'книгу',
        Song: 'музыку',
        Game: 'игру',
        Good: 'товар',
        MobileApp: 'моб. приложение',
        Podcast: 'подкаст',
        Person: 'человек',
        Clip: 'видео',
        CommonItem: 'другое',
        TvShow: 'сериал',
        Article: 'cтатью',
        Website: 'сайт',
        Software: 'программу для работы',
        InstagramChannel: 'Инстаграм страницу',
        TelegramChannel: 'Телеграм канал',
        YoutubeChannel: 'Youtube канал',
        Brand: 'бренд',
        Gadget: 'гаджет',
        Place: 'место',
        Country: 'страну',
        City: 'город',
        Restaurant: 'ресторан',
        Bar: 'бар',
        Cafe: 'кафе',
        Cuisine: 'кулинарию',
        Beauty: 'красоту',
        Fashion: 'моду',
        Training: 'обучение',
        Service: 'сервисы',
        Car: 'автомобиль',
        Hobby: 'хобби',
        Event: 'мероприятие',
        Sport: 'спорт',
        Practice: 'практику',
        Hotel: 'отель'
      },
      pluralize: {
        Movie: '0 фильмов | фильм | фильма | фильмов',
        Book: '0 книг | книгу | книги | книг',
        Song: '0 музыки | музыку | музыки | музыки',
        Game: '0 игр | игру | игры | игр',
        Good: '0 товаров | товар | товара | товаров',
        MobileApp: '0 моб. приложений | моб. приложение | моб. приложения | моб. приложений',
        Podcast: '0 подкастов | подкаст | подкаста | подкастов',
        Person: '0 человек | человек | человека | человек',
        Clip: '0 видео | видео | видео | видео',
        CommonItem: '0 товаров | товар | товара | товаров',
        TvShow: '0 сериалов | сериал | сериала | сериалов',
        Article: '0 статей | статью | статьи | статей',
        Website: '0 сайтов | сайт | сайта | сайтов',
        Software: '0 программ для работы | программу для работы | программы для работы | программы для работы',
        InstagramChannel: '0 инстаграм страниц | инстаграм страница | инстаграм страници | инстаграм страниц',
        TelegramChannel: '0 телеграм каналов | телеграм канал | телеграм канала | телеграм каналов',
        YoutubeChannel: '0 YouTube каналов | YouTube канал | YouTube канала | YouTube каналов',
        Brand: '0 брендов | бренд | бренда | брендов',
        Gadget: '0 гаджетов | гаджет | гаджета | гаджетов',
        Place: '0 мест | место | места | мест',
        Country: '0 стран | страна | страны | стран',
        City: '0 городов | город | города | городов',
        Restaurant: '0 ресторанов | ресторан | ресторана | ресторанов',
        Bar: '0 баров | бар | бара | баров',
        Cafe: '0 кафе | кафе | кафе | кафе',
        Cuisine: '0 кулинарий | кулинария | кулинарии | кулинарий',
        Beauty: '0 предметов красоты | предмет красоты | предмета красоты | предметов красоты',
        Fashion: '0 вещей | вещь | вещи | вещей',
        Training: '0 рекомендаций | рекомендация | рекомендации | рекомендаций',
        Service: '0 сервисов | сервис | сервиса | сервисов',
        Car: '0 автомобилей | автомобиль | автомобиля | автомобилей',
        Hobby: '0 хобби | хобби | хобби | хобби',
        Event: '0 мероприятий | мероприятие | мероприятия | мероприятий',
        Sport: '0 видов спорта | вид спорта | вида спорта | видов спорта',
        Practice: '0 практик | практика | практики | практик',
        Hotel: '0 отелей | отель | отеля | отелей'
      }
    },
    item: {
      type_quote: 'Напишите свой комментарий',
      add: 'Доб.',
      edit: 'Ред.',
      your_quote: 'ВАШЕ МНЕНИЕ',
      quote_link: 'ССЫЛКА НА ЦИТАТУ',
      type_tag: 'ДОБАВЬТЕ ТЕГ',
      buy_link: 'ССЫЛКА В МАГАЗИН',
      add_quote_link: 'Ссылка на цитату',
      add_tags: 'Теги',
      add_buy_link: 'Ссылка в магазин',
      invalidLink: 'Пожалуйста, введите правильную ссылку',
      cancel: 'Отменить',
      save: 'Сохранить'
    },
    item_by_link: {
      preview: 'Просмотр',
      editor: 'Редакт.',
      title: 'Название',
      description: 'Описание',
      director: 'СЪЕМОЧНАЯ ГРУППА',
      actors: 'АКТЁРЫ',
      duration: 'ДЛИТЕЛЬНОСТЬ',
      duration_label: 'Укажите число (в минутах)',
      year: 'ГОД',
      geners: 'ЖАНР',
      album_name: 'АЛЬБОМ',
      singer: 'ИСПОЛНИТЕЛЬ',
      author: 'АВТОР',
      platform: 'ПЛАТФОРМА'
    },
    search: {
      placeholder: 'Впишите название или Вставьте ссылку',
      placeholder_by_link: 'ВСТАВЬТЕ ССЫЛКУ НА РЕСУРС...',
      one_more: 'Добавить еще 1'
    },
    like:{
      one: "лайк",
      few: "лайка",
      many: "лайков",
      other: "лайков"
    }
  },
    //-------------- UA ----------------
    ua: {
      header: {
        statuses: {
          saving: 'Зберігається...',
          saved: 'Збережено'
        },
        search: {
          tooltip_search: 'Почніть пошук...',
          latest_search: 'Останні результати'
        },
        new_flist: 'Новий FLIIST',
        publish: 'Опублікувати',
        close_creation: 'Закрити'
      },
      content: {
        category: {
          title: 'Виберіть категорію',
          placeholder: '// Виберіть свою категорію...'
        },
        flist: {
          title: 'Додайте {name} у свій FLIIST',
          manualy: 'Не знайшли, що шукали? Спробуйте <strong>додати вручну</strong>!',
          not_found: 'Нічого не знайдено. Спробуйте іншу назву або вставте посилання на ресурс...',
          not_found_only_link: 'Вставте посилання на ресурс...',
          you_added: 'Додано:',
          added: {
            Movies: 'фільм | фільми | фільмів'
          },
          private: 'Особистий',
          category: 'Категорія:',
          create_list: 'Ваш новий FLIIST',
          recommendation_cover: 'Обкладинка',
          recommendation_title: 'Назвіть свій FLIIST',
          slug: 'Кастомне посилання',
          recommendation_title_errors: {
            empty: 'Назвіть свій FLIIST',
            size: 'Назва повинна налічувати від 3 до 100 символів.'
          },
          quote_placeholder: '// НАПИШІТЬ КОМЕНТАР ДО ЦЬОГО FLIIST',
          cancel: 'Закрити',
          save: 'Зберегти',
  
          suggestion: {
            Movie: 'Жанр',
            Book: 'Автор',
            Song: 'Музикант',
            Game: 'Платформа',
            Good: 'Опис',
            TvShow: 'Жанр',
            Person: 'Опис',
            MobileApp: 'Опис',
            Podcast: 'Опис',
            Clip: 'Опис',
            CommonItem: 'Опис',
            Article: 'Опис',
            Website: 'Опис',
            Software: 'Опис',
            InstagramChannel: 'Опис',
            TelegramChannel: 'Опис',
            YoutubeChannel: 'Опис',
            Brand: 'Опис',
            Gadget: 'Опис',
            Place: 'Опис',
            Country: 'Опис',
            City: 'Опис',
            Restaurant: 'Опис',
            Bar: 'Опис',
            Cafe: 'Опис',
            Cuisine: 'Опис',
            Beauty: 'Опис',
            Fashion: 'Опис',
            Training: 'Опис',
            Service: 'Опис',
            Car: 'Опис',
            Hobby: 'Опис',
            Event: 'Опис',
            Sport: 'Опис',
            Practice: 'Опис',
            Hotel: 'Опис'
          }
        }
      },
      confirm: {
        title: 'Ви впевнені, що бажаєте почати спочатку?',
        text: 'Ваш поточний прогрес збережено в чорновику. Ви хочете почати спочатку?',
        change: 'Почати спочатку',
        cancel: 'Відміна'
      },
      category_name: {
        single: {
          Movie: 'фільм',
          Book: 'книгу',
          Song: 'музику',
          Game: 'гру',
          Good: 'товар',
          MobileApp: 'моб. додаток',
          Podcast: 'подкаст',
          Person: 'людина',
          Clip: 'відео',
          CommonItem: 'інше',
          TvShow: 'серіал',
          Article: 'cтаттю',
          Website: 'сайт',
          Software: 'програму для роботи',
          InstagramChannel: 'Інстаграм-сторінку',
          TelegramChannel: 'Телеграм-канал',
          YoutubeChannel: 'Youtube-канал',
          Brand: 'бренд',
          Gadget: 'гаджет',
          Place: 'місце',
          Country: 'країну',
          City: 'місто',
          Restaurant: 'ресторан',
          Bar: 'бар',
          Cafe: 'кафе',
          Cuisine: 'кулінарію',
          Beauty: 'красу',
          Fashion: 'моду',
          Training: 'навчання',
          Service: 'сервіси',
          Car: 'автомобіль',
          Hobby: 'хобі',
          Event: 'захід',
          Sport: 'спорт',
          Practice: 'практику',
          Hotel: 'готель'
        },
        pluralize: {
          Movie: '0 фільмів | фільм | фільми | фільмів',
          Book: '0 книг | книгу | книги | книг',
          Song: '0 музики | музику | музики | музики',
          Game: '0 ігор | гру | гри | ігор',
          Good: '0 товарів | товар | товари| товарів',
          MobileApp: '0 моб. додатків | моб. додаток | моб. додатки | моб. додатків',
          Podcast: '0 подкастів | подкаст | подкасти | подкастів',
          Person: '0 людей | людей | людини | людей',
          Clip: '0 відео | відео | відео | відео',
          CommonItem: '0 товарів | товар | товари | товарів',
          TvShow: '0 серіалів | серіал | серіали | серіалів',
          Article: '0 статей | статтю | статті | статей',
          Website: '0 сайтів | сайт | сайти | сайтів',
          Software: '0 програм для роботи | програму для роботи | програми для роботи | програми для роботи',
          InstagramChannel: '0 інстаграм-сторінок | інстаграм-сторінка | інстаграм-сторінки | інстаграм-сторінок',
          TelegramChannel: '0 телеграм-каналів | телеграм-канал | телеграм-канали | телеграм-каналів',
          YoutubeChannel: '0 YouTube-каналів | YouTube-канал | YouTube-канали | YouTube-каналів',
          Brand: '0 брендів | бренд | бренди | брендів',
          Gadget: '0 гаджетів | гаджет | гаджети | гаджетів',
          Place: '0 місць | місце | місця | місць',
          Country: '0 країн | країна| країни | країн',
          City: '0 міст | місто | міста | міст',
          Restaurant: '0 ресторанів | ресторан | ресторани | ресторанів',
          Bar: '0 барів | бар | бари | барів',
          Cafe: '0 кафе | кафе | кафе | кафе',
          Cuisine: '0 кулінарії | кулінарія | кулінарії | кулінарії',
          Beauty: '0 предметів краси | предмет краси | предмета краси | предметів краси',
          Fashion: '0 речей | річ | речі | речей',
          Training: '0 рекомендацій | рекомендація | рекомендації | рекомендацій',
          Service: '0 сервісів | сервіс | сервіси | сервісів',
          Car: '0 автомобілів | автомобіль | автомобілі | автомобілів',
          Hobby: '0 хобі | хобі | хобі | хобі',
          Event: '0 заходів | захід | заходи | заходів',
          Sport: '0 видів спорту | вид спорту | види спорту | видів спорту',
          Practice: '0 практик | практика | практики | практик',
          Hotel: '0 готелів | готель | готелі | готелів'
        }
      },
      item: {
        type_quote: 'Напишіть свій коментар',
        add: 'Дод.',
        edit: 'Ред.',
        your_quote: 'ВАША ДУМКА',
        quote_link: 'ПОСИЛАННЯ НА ЦИТАТУ',
        type_tag: 'ДОДАЙТЕ ТЕГ',
        buy_link: 'ПОСИЛАННЯ В МАГАЗИН',
        add_quote_link: 'Посилання на цитату',
        add_tags: 'Теги',
        add_buy_link: 'Посилання в магазин',
        invalidLink: 'Будь ласка, введіть правильне посилання',
        cancel: 'Відмінити',
        save: 'Зберегти'
      },
      item_by_link: {
        preview: 'Перегляд',
        editor: 'Редаг.',
        title: 'Назва',
        description: 'Опис',
        director: 'ЗНІМАЛЬНА ГРУПА',
        actors: 'АКТОРИ',
        duration: 'ТРИВАЛІСТЬ',
        duration_label: 'Вкажіть кількість (в хвилинах)',
        year: 'РІК',
        geners: 'ЖАНР',
        album_name: 'АЛЬБОМ',
        singer: 'ВИКОНАВЕЦЬ',
        author: 'АВТОР',
        platform: 'ПЛАТФОРМА'
      },
      search: {
        placeholder: 'Впишіть назву або Вставте посилання',
        placeholder_by_link: 'ВСТАВТЕ ПОСИЛАННЯ НА РЕСУРС...',
        one_more: 'Додати ще 1'
      },
      like:{
        one: "лайк",
        few: "лайка",
        many: "лайків",
        other: "лайків",
      }
    },
    //-------------- UA ----------------
    es: {
      header: {
        statuses: {
          saving: 'Guardando en Borrador...',
          saved: 'Guardado en Borrador'
        },
        search: {
          tooltip_search: 'Buscar aquí...',
          latest_search: 'Última búsqueda'
        },
        new_flist: 'FLIIST Nuevo ',
        publish: 'Publicar',
        close_creation: 'Cerrar Creación'
      },
      content: {
        category: {
          title: 'Elige tu categoría',
          placeholder: '// Elije tu categoría aquí...'
        },
        flist: {
          title: 'Añade un {name} a tu FLIIST',
          manualy: 'Si no encuentras nada, entonces añade <strong>manually</strong>.',
          not_found: 'No hemos encontrado nada. Por favor, intenta con otro nombre o introduce un enlace...',
          not_found_only_link: 'Por favor, introduce un enlace...',
          you_added: 'Añadido:',
          added: {
            Movies: 'película | películas'
          },
          private: 'Privado',
          category: 'Categoría:',
          create_list: 'Crea tu lista',
          recommendation_cover: 'Portada de la Recomendación',
          recommendation_title: 'Título de tu FLIIST',
          slug: 'Slug personalizado',
          recommendation_title_errors: {
            empty: 'Por favor, introduce el título',
            size: 'El título debe tener un mínimo de 3 y un máximo de 100 caracteres.'
          },
          quote_placeholder: 'TU COMENTARIO',
          cancel: 'Cancelar',
          save: 'Guardar',
  
          suggestion: {
            Movie: 'Géneros',
            Book: 'Autor',
            Song: 'Artista',
            Game: 'Plataforma',
            Good: 'Descripción',
            TvShow: 'Géneros',
            Person: 'Descripción',
            MobileApp: 'Descripción',
            Podcast: 'Descripción',
            Clip: 'Descripción',
            CommonItem: 'Descripción',
            Article: 'Descripción',
            Website: 'Descripción',
            Software: 'Descripción',
            InstagramChannel: 'Descripción',
            TelegramChannel: 'Descripción',
            YoutubeChannel: 'Descripción',
            Brand: 'Descripción',
            Gadget: 'Descripción',
            Place: 'Descripción',
            Country: 'Descripción',
            City: 'Descripción',
            Restaurant: 'Descripción',
            Bar: 'Descripción',
            Cafe: 'Descripción',
            Cuisine: 'Descripción',
            Beauty: 'Descripción',
            Fashion: 'Descripción',
            Training: 'Descripción',
            Service: 'Descripción',
            Car: 'Descripción',
            Hobby: 'Descripción',
            Event: 'Descripción',
            Sport: 'Descripción',
            Practice: 'Descripción',
            Hotel: 'Descripción'
          }
        }
      },
      confirm: {
        title: '¿Seguro que quieres volver a empezar de nuevo?',
        text: 'Vas a cambiar de categoría y perderás tu progreso actual.',
        change: 'Cambiar',
        cancel: 'Cancelar'
      },
      category_name: {
        single: {
          Movie: 'película',
          Book: 'libro',
          Song: 'canción',
          Game: 'juego',
          Good: 'producto',
          MobileApp: 'aplicación móvil',
          Podcast: 'pódcast',
          Person: 'persona',
          Clip: 'vídeo',
          CommonItem: 'otros',
          TvShow: 'serie',
          Article: 'artículo',
          Website: 'página web',
          Software: 'software',
          InstagramChannel: 'página de Instagram',
          TelegramChannel: 'canal de Telegram',
          YoutubeChannel: 'canal de Youtube',
          Brand: 'marca',
          Gadget: 'dispositivo',
          Place: 'lugar',
          Country: 'país',
          City: 'ciudad',
          Restaurant: 'restaurante',
          Bar: 'bar',
          Cafe: 'cafetería',
          Cuisine: 'cocina',
          Beauty: 'belleza',
          Fashion: 'moda',
          Training: 'aprendizaje',
          Service: 'servicio',
          Car: 'coche',
          Hobby: 'afición',
          Event: 'evento',
          Sport: 'deporte',
          Practice: 'práctica',
          Hotel: 'hotel'
        },
        pluralize: {
          Movie: '0 películas | película | películas | películas',
          Book: '0 libros | libro | libros | libros',
          Song: '0 canciónes | canción | canciónes | canciónes',
          Game: '0 juegos | juego | juegos | juegos',
          Good: '0 productos | producto | productos | productos',
          Restaurant: '0 restaurantes | restaurante | restaurantes | restaurantes',
          MobileApp: '0 aplicaciones móviles | aplicación móvil | aplicaciones móviles | aplicaciones móviles',
          Podcast: '0 pódcast | pódcast | pódcast | pódcast',
          Person: '0 personas | persona | personas | personas',
          Clip: '0 vídeos | video | vídeos | vídeos',
          CommonItem: '0 productos | producto | productos | productos',
          TvShow: '0 series |  serie |  series | series',
          Article: '0 artículos | artículo | artículos | artículos',
          Website: '0 páginas web | página web | páginas web | páginas web',
          Software: '0 software | software | software | software',
          InstagramChannel: '0 páginas de Instagram | página de Instagram | páginas de Instagram | páginas de Instagram',
          TelegramChannel: '0 canales de Telegram | canal de Telegram | canales de Telegram | canales de Telegram',
          YoutubeChannel: '0 canales de Youtube | canal de Youtube | canales de Youtube | canales de Youtube',
          Brand: '0 marcas | marca | marcas | marcas',
          Gadget: '0 dispositivos | dispositivo | dispositivos | dispositivos',
          Place: '0 lugares | lugar | lugares | lugares',
          Country: '0 países |  país |  países | países',
          City: '0 ciudades |  ciudad |  ciudades | ciudades',
          Restaurant: '0 restaurantes |  restaurante |  restaurantes | restaurantes',
          Bar: '0 bares |  bar |  bares | bares',
          Cafe: '0 cafeterías |  cafetería |  cafeterías | cafeterías',
          Cuisine: '0 cocinas |  cocina |  cocinas | cocinas',
          Beauty: '0 belleza |  belleza |  belleza | belleza',
          Fashion: '0 moda |  moda |  moda | moda',
          Training: '0 apredizaje |  apredizaje |  apredizaje | apredizaje',
          Service: '0 servicios |  servicio |  servicios | servicios',
          Car: '0 coches |  coche |  coches | coches',
          Hobby: '0 aficiones |  afición |  aficiones | aficiones',
          Event: '0 eventos |  evento |  eventos | eventos',
          Sport: '0 deporte |  deporte |  deporte | deporte',
          Practice: '0 prácticas |  práctica |  prácticas | prácticas',
          Hotel: '0 hoteles |  hotel |  hoteles | hoteles'
        }
      },
      item: {
        type_quote: 'Escribe tu cita aquí',
        add: 'Añadir',
        edit: 'Editar',
        your_quote: 'Tu Comentario',
        quote_link: 'ENLACE DE LA CITA',
        type_tag: 'AÑADIR ETIQUETA (Presiona Intro)',
        buy_link: 'ENLACE PARA COMPRAR',
        add_quote_link: 'Añadir Enlace de la Cita',
        add_tags: 'Anadir Etiquetas',
        add_buy_link: 'Añadir Enlace para Comprar',
        invalidLink: 'Por favor, introduce un enlace válido',
        cancel: 'Cancelar',
        save: 'Guardar'
      },
      item_by_link: {
        preview: 'Vista previa',
        editor: 'Editar',
        title: 'Título ',
        description: 'Descripción',
        director: 'DIRECTOR',
        actors: 'ACTORES',
        duration: 'DURACIÓN',
        duration_label: 'DURACIÓN (minutos)',
        year: 'AÑO',
        geners: 'GÉNEROS',
        album_name: 'NOMBRE DEL ÁLBUM',
        singer: 'CANTANTE',
        author: 'AUTOR',
        platform: 'PLATAFORMA'
      },
      search: {
        placeholder: 'Busca aquí o introduce un enlace',
        placeholder_by_link: 'INTRODUCE UN ENLACE AQUÍ...',
        one_more: 'Añadir 1 más'
      },
      like:{
        one: "me gusta",
        few: "me gusta",
        many: "me gusta",
        other: "me gusta"
      }
    },
};
