import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';
import Search from '../../../search_app/search.vue';
import VueI18n from 'vue-i18n';
import { messages } from '@/recommendations_app/utils/locales';

Vue.component('search', Search);

Vue.use(TurbolinksAdapter);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages // set locale messages
});

document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('#searchApp') != null) {
    const search = new Vue({
      i18n,
      el: '[data-behavior="vue"]'
    });
  }
});

Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});
