import { i18n } from 'src/javascript/components/i18n';

const axios = require('axios');

function details_in_popup_hover(user_id){

    const locale = $('html').attr('lang');

    axios({
        url: `/my_profile/hover_user_get`,
        params: {
            hover_user_get: user_id,
            locale: locale
        }, 
        cache: true
      })
        .then(function(response) {
         $('#user_'+ user_id).html(response.data);
         $('.js-hover').popover("update");
        })
        .catch(function(error) {
          console.log(error);
        })

        return '<div class="hover_user" id="user_'+ user_id +'"><br /><br /><br /><br />'+ i18n[locale]['loading'] + '</div>';
}


function user_hover(element){
    element.popover({
        html: true,
        placement: "bottom",
        trigger: 'custom',
        container: "body",
        animation: false,
        content: function () {
            return details_in_popup_hover($(element).attr('data-uid'));
        }
    }).on("mouseenter", function() {
        var _this = this;
        $(this).popover("show");
        $(".popover").on("mouseleave", function() {
          $(_this).popover('hide');
        });
      }).on("mouseleave", function() {
        var _this = this;
        setTimeout(function() {
          if (!$(".popover:hover").length) {
            $(_this).popover("hide");
          }
        }, 0);
      });

}

$(document).on('turbolinks:load', function() {

    $('.js-hover').each(function () {
        var $this = $(this);
        user_hover($this);
    });

});


$(document).on('pagination:loaded', function(e, ...items) {

    $(items).each(function() {
      if ( $(this).hasClass('feed_entity') || $(this).hasClass('top__card') ) {
        $(this).find('.js-hover').each(function () {
            var $this = $(this);
            user_hover($this);
        });     
      }
    });
  });